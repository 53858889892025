import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { CanvasService } from '../services/canvas.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

const SERVERS: any = {
  iceServers: [
    { urls: 'stun:stun.services.mozilla.com' },
    { urls: 'stun:stun.l.google.com:19302' }
  ]
};

const DEFAULT_CONSTRAINTS = {
  optional: []
};

declare let RTCPeerConnection: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('canvasModelCloseBtn') public canvasModelCloseBtn: ElementRef;
  @ViewChild('powerpointModelCloseBtn') public powerpointModelCloseBtn: ElementRef;
  @ViewChild('joinSessionModelCloseBtn') public joinSessionModelCloseBtn: ElementRef;
  @ViewChild('iframeVideo') public iframeVideo: ElementRef;
  @ViewChild('myModal') public myModal: ElementRef;
  @ViewChild('userDetailsPopOverDiv') public userDetailsPopOverDiv: ElementRef;
  @ViewChild('chooseUserImage') public chooseUserImage: ElementRef;
  @ViewChild('saveUserImageDiv') public saveUserImageDiv: ElementRef;
  @ViewChild('editUserImageDiv') public editUserImageDiv: ElementRef;
  @ViewChild('imgUploadProgressDiv') public imgUploadProgressDiv: ElementRef;
  displayName = '';
  userUID = '';
  pagesDataObservable: Observable<any[]>;
  dataArray: any[] = new Array();
  shareText = '';
  pagesJoinedSessionsObservable: Observable<any[]>;
  joinedSessionArray: any[] = new Array();
  RTCpeersArray: any[] = new Array();
  file: any;
  files: any;
  pptArray: any[] = new Array();
  index: any = 0;
  shareCode: any = '';
  isLoading = false;
  sessionName: any = '';
  videoRecordingsObservable: Observable<any[]>;
  videoRecordingsArray: any[] = new Array();
  public color1 = '#2889e9';
  username: any = '';
  emailId: any = '';
  imageUrl: any = '../../assets/images/user_avatar.png';
  className: any = 'over50 p50';
  imageFile: any = null;
  imgUploadErrMsg: any = '';
  isImageSelected = false;
  userDetailsKey: any;
  imageUploadProgress: any = 0;

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private zone: NgZone,
    private canvasService: CanvasService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams['videoUrl']) {
      const decodedString = atob(this.activatedRoute.snapshot.queryParams['videoUrl']);
      this.canvasService.setVideoUrlObject(decodedString);
    }
    this.authenticationService.checkAuthentication(this);
  }

  goToLogin() {
    this.zone.run(() => this.router.navigateByUrl('/login'));
  }

  setUserUID(userUID) {
    this.userUID = userUID;
  }

  setUsername(displayName) {
    this.displayName = displayName;
  }

  setUserDetails(username, email, imageUrl, key) {
    this.username = username;
    this.emailId = email;
    console.log('imageUrl: ' + imageUrl);
    if (imageUrl !== '') {
      this.imageUrl = imageUrl;
    }
    this.userDetailsKey = key;
  }

  getDashboardData() {
    this.canvasService.getSessionHistory(this, this.userUID);
    this.canvasService.getJoinedSessionsHistory(this, this.userUID);
    this.canvasService.getRecordedVideosHistory(this, this.userUID);
  }

  setPagesDataArray(sessionHeaderId, pageData, shareCode, type, sessionType, sessionName, createdDate) {
    if (type === 'child_added') {
      this.dataArray.push({sessionHeaderId: sessionHeaderId,
        pageData: pageData,
        shareCode: shareCode,
        sessionType: sessionType,
        sessionName: sessionName,
        createdDate: createdDate
      });
    } else if (type === 'child_changed') {
      for (let i = 0; i < this.dataArray.length; i++) {
        if (this.dataArray[i].sessionHeaderId === sessionHeaderId) {
          this.dataArray[i].pageData = pageData;
          break;
        }
      }
    }
    this.pagesDataObservable = new Observable(observer => {
      observer.next(this.dataArray);
    });
    // this.pagesDataArray.push({
    //   sessionHeaderId: sessionHeaderId,
    //   pageData: pageData
    // });
  }

  setJoinedSessionsArray(sessionHeaderId, pageData, shareCode, type, sessionType, sessionName, joinedDate) {
    if (type === 'child_added') {
      this.joinedSessionArray.push({sessionHeaderId: sessionHeaderId,
        pageData: pageData,
        shareCode: shareCode,
        sessionType: sessionType,
        sessionName: sessionName,
        joinedDate: joinedDate
      });
    } else if (type === 'child_changed') {
      for (let i = 0; i < this.joinedSessionArray.length; i++) {
        if (this.joinedSessionArray[i].sessionHeaderId === sessionHeaderId) {
          this.joinedSessionArray[i].pageData = pageData;
          break;
        }
      }
    }
    this.pagesJoinedSessionsObservable = new Observable(observer => {
      observer.next(this.joinedSessionArray);
    } );
  }

  onSessionRemoved(sessionHeaderId) {
    for (let i = 0; i < this.dataArray.length; i++) {
      if (this.dataArray[i].sessionHeaderId === sessionHeaderId) {
        this.dataArray.splice(i, 1);
        break;
      }
    }
    this.pagesDataObservable = new Observable(observer => {
      observer.next(this.dataArray);
    });
  }

  onJoinedSessionRemoved(sessionHeaderId) {
    for (let i = 0; i < this.joinedSessionArray.length; i++) {
      if (this.joinedSessionArray[i].sessionHeaderId === sessionHeaderId) {
        this.joinedSessionArray.splice(i, 1);
        break;
      }
    }
    this.pagesJoinedSessionsObservable = new Observable(observer => {
      observer.next(this.joinedSessionArray);
    });
  }

  goToCanvas(sessionHeaderId, shareCode, sessionType) {
    this.isLoading = false;
    this.canvasService.setCanvasComponentData(sessionHeaderId, this.userUID, true, shareCode, sessionType);
    this.zone.run(() => this.router.navigateByUrl('/home'));
  }

  checkShareCode() {
    this.joinSessionModelCloseBtn.nativeElement.click();
    if (this.shareText !== '') {
      this.isLoading = true;
      this.canvasService.checkShareCode(this, this.shareText);
    }
  }

  signOut() {
    this.authenticationService.signOut(this);
  }

  fileSelected($event) {
    this.files = $event.target.files;
    this.file = this.files[0];
  }

  createPowerpointSession() {
    this.powerpointModelCloseBtn.nativeElement.click();
    if (this.file) {
      this.isLoading = true;
      const reader = new FileReader();
      reader.onload = function(readerEvt) {
        const binaryString = readerEvt.target.result;
        this.http.post('https://v2.convertapi.com/convert/pptx/to/jpg?Secret=q4f8Fb0VcumbXzA1', {
        'Parameters': [
          {
              'Name': 'File',
              'FileValue': {
                  'Name': 'my_file.pptx',
                  'Data': btoa(binaryString)
              }
          },
          {
              'Name': 'StoreFile',
              'Value': true
          }
      ]
      })
      .subscribe(
        res => {
          console.log(res);
          for (let i = 0; i < res.Files.length; i++) {
            console.log(res.Files[i].Url);
            this.pptArray.push({
              url: res.Files[i].Url
            });
          }
          this.createSession('powerpoint');
        },
        err => {
          this.isLoading = false;
          console.log('Error occured');
        }
      );


    }.bind(this);
    reader.readAsBinaryString(this.file);
    }
  }

  sendPPTimageToServer(sessionHeaderId) {
    const tempCanvas = document.createElement('canvas');
    const tempctx = tempCanvas.getContext('2d');
    const img = new Image();
    img.onload = function() {
      this.index++;
      tempCanvas.width = 500;
      tempCanvas.height = 400;
      tempctx.drawImage(img, 0, 0, img.width,    img.height,     // source rectangle
        0, 0, tempCanvas.width, tempCanvas.height);
      this.canvasService.savePPTimages(sessionHeaderId, tempCanvas.toDataURL('image/png', 0.1), this.index);
      if (this.index < this.pptArray.length) {
        this.sendPPTimageToServer(sessionHeaderId);
      } else {
        this.goToCanvas(sessionHeaderId, this.shareCode, 'powerpoint');
      }
    }.bind(this);
    img.src = this.pptArray[this.index].url;
    img.crossOrigin = 'Anonymous';
  }

  generateShareCode() {
    this.shareCode = Math.random().toString(36).substr(2, 6);
  }

  createSession(sessionType) {
    // this.canvasModelCloseBtn.nativeElement.click();
    const canvasName = 'canvas_' + this.getRandomText(6);
    this.isLoading = true;
    this.generateShareCode();
    this.canvasService.intiateSession(this, this.userUID, this.shareCode, '', '', sessionType, canvasName);
  }

  removeLoader() {
    this.isLoading = false;
  }

  removeSession(sessionHeaderId, type) {
    if (window.confirm('Are sure you want to delete this session ?')) {
      this.isLoading = true;
      this.canvasService.removeSessionService(this, sessionHeaderId, type);
    }
  }

  removeFromJoinedSession(sessionHeaderId) {
    if (window.confirm('Are sure you want to delete this session ?')) {
      this.isLoading = true;
      this.canvasService.removeJoinedSession(this, sessionHeaderId, this.userUID);
    }
  }

  onInput(event, sessionHeaderId) {
    console.log(event);
    console.log(event.target.value);
    console.log('sessionHeaderId ' + sessionHeaderId);
    this.canvasService.updateSessionName(sessionHeaderId, event.target.value);
  }

  onUserNameInputChange(event) {
    this.username = event.target.value;
    this.canvasService.updateUserName(this, this.userDetailsKey, this.username);
  }

  closeModal() {
    this.canvasModelCloseBtn.nativeElement.click();
  }

  setRecordedVideos(type, videoId, createdDate, sessionName, sessionheaderId, videoUrl, thumbnail) {
    const encodeString = btoa(videoUrl);
    const shareUrl = window.location.href + '?videoUrl=' + encodeString;
    // console.log("Share Url " + shareUrl);
    this.videoRecordingsArray.push({
      videoId: videoId,
      createdDate: createdDate,
      sessionName: sessionName,
      sessionHeaderId: sessionheaderId,
      videoUrl: videoUrl,
      thumbnail: thumbnail,
      shareUrl: shareUrl
    });

    this.videoRecordingsObservable = new Observable(observer => {
      observer.next(this.videoRecordingsArray);
    } );

  }

  onVideoRemoved(videoId) {
    for (let i = 0; i < this.videoRecordingsArray.length; i++) {
      if (this.videoRecordingsArray[i].videoId === videoId) {
        this.videoRecordingsArray.splice(i, 1);
        break;
      }
    }
    this.videoRecordingsObservable = new Observable(observer => {
      observer.next(this.videoRecordingsArray);
    });
  }

  setVideoUrl(url) {
    this.iframeVideo.nativeElement.src = url;
  }

  pauseVideo() {
    this.iframeVideo.nativeElement.pause();
  }

  checkForVideoUrl() {
    this.canvasService.checkVideoUrl(this);
  }

  showVideoModal(videoUrl) {
    this.setVideoUrl(videoUrl);
    document.getElementById('modalOpenBtn').click();
    this.canvasService.unsetVideoUrlObject();
  }

  copyVideoShareUrl(shareUrl) {
    const $temp = $('<input>');
    $('body').append($temp);
    $temp.val(shareUrl).select();
    document.execCommand('copy');
    $temp.remove();
    this.showSnackbar('Text copied');
  }

  showSnackbar(message) {
    const x = document.getElementById('snackbar');
    x.className = 'show';
    x.innerHTML = message;
    setTimeout(function() { x.className = x.className.replace('show', ''); }, 3000);
  }

  onDeleteVideoClicked(videoUrl, videoId) {
    this.isLoading = true;
    this.canvasService.deleteVideo(this, videoUrl, videoId);
  }

  getRandomText(length) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  toggleUserPopOver() {
    this.userDetailsPopOverDiv.nativeElement.click();
  }

  onImageError() {
    this.imageUrl = '../../assets/images/user_avatar.png';
  }

  chooseImage() {
    this.chooseUserImage.nativeElement.click();
  }

  imageSelected(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      const reader = new FileReader();
      reader.onload = function() {
        this.isImageSelected = true;
        this.imageUrl = reader.result;
      }.bind(this);
      this.imageFile = event.target.files[0];
      // console.log("files " + event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
      this.imgUploadErrMsg = '';
      event.target.value = '';
      this.saveUserImageDiv.nativeElement.style.visibility = 'visible';
    } else {
      this.imgUploadErrMsg = 'Invalid file';
      this.saveUserImageDiv.nativeElement.style.visibility = 'hidden';
    }
  }

  saveUserImage() {
    if (this.imageFile != null) {
        this.canvasService.saveUserImage(this, this.imageFile, this.userDetailsKey);
        this.imgUploadErrMsg = '';
        this.saveUserImageDiv.nativeElement.style.visibility = 'hidden';
        this.editUserImageDiv.nativeElement.style.visibility = 'hidden';
        this.imgUploadProgressDiv.nativeElement.style.visibility = 'visible';
    } else {
      this.imgUploadErrMsg = 'No file';
      this.saveUserImageDiv.nativeElement.style.visibility = 'hidden';
    }
  }

  setUserImageUploadProgress(progress) {
    this.imageUploadProgress = progress;
    if (progress >= 50) {
      this.className = 'over50 p' + progress;
    } else {
      this.className = 'p' + progress;
    }
  }

  setUserImage(imageUrl) {
    this.imageUrl = imageUrl;
  }

  onImageUploadError(error) {
    this.imgUploadErrMsg = error;
    this.saveUserImageDiv.nativeElement.style.visibility = 'hidden';
    this.editUserImageDiv.nativeElement.style.visibility = 'visible';
    this.imgUploadProgressDiv.nativeElement.style.visibility = 'hidden';
  }

  onImageUploadSuccess() {
    this.saveUserImageDiv.nativeElement.style.visibility = 'hidden';
    this.editUserImageDiv.nativeElement.style.visibility = 'visible';
    this.imgUploadProgressDiv.nativeElement.style.visibility = 'hidden';
    this.showSnackbar('Successfully changed user image');
  }
}
