import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { CanvasService } from "../services/canvas.service";
import { HttpClient } from '@angular/common/http';

const SERVERS: any = {
  iceServers: [
    { urls: "stun:stun.services.mozilla.com" },
    { urls: "stun:stun.l.google.com:19302" }
  ]
};

const DEFAULT_CONSTRAINTS = {
  optional: []
};

declare let RTCPeerConnection: any;

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.css']
})
export class GuestComponent implements OnInit {

  @ViewChild('myCanvas') public canvas: ElementRef;
  context: any;
  canvasEl: HTMLCanvasElement;
  snapshot: any;
  snapshotArray:any[] = new Array();
  step:any = -1;
  count:any = 0;
  coordinatesArray:any[] = new Array();
  isImageLoading:boolean = false;
  pc:any
  localStream:any = null;
  audioElement:any = null;
  userID:string;
  convertapi:any;
  detailsArray:any[] = new Array();
  index:any = 0;
  constructor(private canvasService: CanvasService, private http: HttpClient) { }

  ngOnInit() {
    // this.userID = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  takeSnapshot(){
    this.snapshot = this.context.getImageData(0, 0, this.canvasEl.width, this.canvasEl.height);
  }

  restoreSnapshot(){
    this.context.putImageData(this.snapshot, 0, 0);
  }

  pushSnapshot(){
    this.takeSnapshot();
    this.step++;
    if (this.step < this.snapshotArray.length) { this.snapshotArray.length = this.step; }
    this.snapshotArray.push(this.snapshot);
  }

  undoCanvas(){
    
    if(this.step > 0){
      this.step--;
      this.context.putImageData(this.snapshotArray[this.step], 0, 0);
    }
  }

  redoCanvas(){
    if(this.step < this.snapshotArray.length - 1){
      this.step++;
      this.context.putImageData(this.snapshotArray[this.step], 0, 0);
    }
  }

  drawLine(startPointX, startPointY, currentPointX, currentPointY){
    this.context.beginPath();
    this.context.moveTo(startPointX, startPointY);
    this.context.lineTo(currentPointX,currentPointY);
    this.context.stroke();
  }

  drawCircle(startPointX, startPointY, currentPointX, currentPointY, isFill){
    var radius = Math.sqrt(Math.pow((startPointX - currentPointX),2) + Math.pow((startPointY - currentPointY),2));
    this.context.beginPath();
    this.context.arc(startPointX, startPointY, radius, 0, 2 * Math.PI, false);
    if(isFill){
      this.context.fill();
    }else{
      this.context.stroke();
    }
  }

  drawEllipse(startPointX, startPointY, currentPointX, currentPointY, isFill){
    this.context.save();
    this.context.beginPath();
    //Dynamic scaling
    var scalex = 1*((currentPointX-startPointX)/2);
    var scaley = 1*((currentPointY-startPointY)/2);
    this.context.scale(scalex,scaley);
    //Create ellipse
    var centerx = (startPointX/scalex)+1;
    var centery = (startPointY/scaley)+1;
    this.context.arc(centerx, centery, 1, 0, 2*Math.PI);
    //Restore and draw
    this.context.restore();
    if(isFill){
      this.context.fill();
    }else{
      this.context.stroke();
    }
  }

  drawFreehand(startPointX, startPointY, currentPointX, currentPointY){
    this.context.beginPath();
    this.context.moveTo(startPointX, startPointY);
    this.context.lineTo(currentPointX, currentPointY);
    this.context.stroke();
  }

  drawRectangle(startPointX, startPointY, rectangleWidth, rectangleHeight, isFill){
    this.context.beginPath();
    this.context.rect(startPointX, startPointY, rectangleWidth, rectangleHeight);
    if(isFill){
      this.context.fill();
    }else{
      this.context.stroke();
    }
  }

  setCanvasText(startPointX, startPointY, inputText){
    this.context.font = "20px sans-serif";
    this.context.fillText(inputText, startPointX, startPointY);
  }

  setCanvasImage(x, y, width, height, url){
    this.isImageLoading = true;
    var img = new Image();
    img.onload = function(){
      this.context.drawImage(img, x, y, width, height);
      this.pushSnapshot();
      this.isImageLoading = false;
      for(var i = 0; i < this.coordinatesArray.length; i++){
        if(this.coordinatesArray[i].type === "imagearea"){
          this.draw(this.coordinatesArray[i].startPointX, 
            this.coordinatesArray[i].startPointY,
            this.coordinatesArray[i].currentPointX,
            this.coordinatesArray[i].currentPointY,
            this.coordinatesArray[i].type,
            this.coordinatesArray[i].isFill,
            this.coordinatesArray[i].lineWidth,
            this.coordinatesArray[i].canvasColor);
            this.coordinatesArray.splice(0,i+1);
            break;
        }else{
          this.draw(this.coordinatesArray[i].startPointX, 
            this.coordinatesArray[i].startPointY,
            this.coordinatesArray[i].currentPointX,
            this.coordinatesArray[i].currentPointY,
            this.coordinatesArray[i].type,
            this.coordinatesArray[i].isFill,
            this.coordinatesArray[i].lineWidth,
            this.coordinatesArray[i].canvasColor);
        }
    
      }
    }.bind(this);
    img.src = url;
    img.crossOrigin = "Anonymous";
  }

  eraseCanvas(positionX, positionY){
    this.context.clearRect(positionX, positionY, 50, 50); 
  }

  draw(startPointX, startPointY, currentPointX, currentPointY, type, isFill, lineWidth, canvasColor){
    if(this.isImageLoading){
      this.coordinatesArray.push({
        startPointX: startPointX,
        startPointY: startPointY,
        currentPointX: currentPointX,
        currentPointY: currentPointY,
        type: type,
        isFill: isFill,
        lineWidth: lineWidth,
        canvasColor: canvasColor
      });
    }else{
      this.context.strokeStyle = canvasColor;
      this.context.fillStyle = canvasColor;
      this.context.lineWidth = lineWidth;
      switch(type){
        case "freehand":
          this.drawFreehand(startPointX,startPointY,currentPointX,currentPointY);
          break;
        case "circle":
          this.drawCircle(startPointX,startPointY,currentPointX,currentPointY,isFill);
          break;
        case "line":
          this.drawLine(startPointX,startPointY,currentPointX,currentPointY);
          break;
        case "rectangle":
          this.drawRectangle(startPointX,startPointY,currentPointX,currentPointY,isFill);
          break;
        case "ellipse":
          this.drawEllipse(startPointX,startPointY,currentPointX,currentPointY,isFill);
          break;
        case "eraser":
          this.eraseCanvas(startPointX,startPointY);
          break;
        case "pushSnapshot":
          this.pushSnapshot();
          break;
        case "undo":
          this.undoCanvas();
          break;
        case "redo":
          this.redoCanvas();
          break;
        case "textarea":
          this.setCanvasText(startPointX, startPointY, currentPointX);
          break;
        case "imagearea":
          this.setCanvasImage(startPointX, startPointY, currentPointX, currentPointY, isFill); //isFill is holding the url for this condition
          break;
        case "background":
          this.setCanvasBackground(startPointX); //the selected background is stored in the startPointX
          break;
      }
    }
  }

  clearCanvas(){
    this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    this.step = -1;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.resizeCanvas();

  // clearTimeout(this.resizeTimeout);
  // this.resizeTimeout = setTimeout(this.res,200);      
     
  }

  resizeCanvas(){
    // this.takeSnapshot();
    var w = document.documentElement.clientWidth
    || document.body.clientWidth || window.innerWidth;

    var h = document.documentElement.clientHeight
    || document.body.clientHeight || window.innerHeight;

    this.canvasEl.width = 3000;
    this.canvasEl.height = 1500;
    // this.restoreSnapshot();
  }

   setCanvasBackground(selected){
    switch(selected){
      case "hLine":
        this.canvasEl.style.backgroundSize = "25px 25px";
        this.canvasEl.style.backgroundImage = "linear-gradient(to bottom, rgb(216, 233, 223) 1px, transparent 1px)";
        break;
      case "vLine":
        this.canvasEl.style.backgroundSize = "25px 25px";
        this.canvasEl.style.backgroundImage = "linear-gradient(to right, rgb(216, 233, 223) 2px, transparent 1px)";
        break;
      case "dot":
        this.canvasEl.style.backgroundSize = "40px 40px";
        this.canvasEl.style.backgroundImage = "radial-gradient(circle,#979797 1px, rgba(0, 0, 0, 0) 1px)";
        break;
      case "grid":
        this.canvasEl.style.backgroundSize = "25px 25px";
        this.canvasEl.style.backgroundImage = "linear-gradient(to right, rgba(229, 235, 237, 1) 1px, transparent 1px), linear-gradient(to bottom, rgba(229, 235, 237, 1) 1px, transparent 1px)";
        break;
    }
  }

  createAnswer(type, target, content){
    console.log("json content" + JSON.parse(content));
    if(target === this.userID){
      if(type === "video-offer"){
        var desc = new RTCSessionDescription(JSON.parse(content));

        this.pc.setRemoteDescription(desc).then(function () {
          return navigator.mediaDevices.getUserMedia({ audio: true });
        })
        .then(function(stream) {
          this.localStream = stream;
          this.audioElement.src = this.localStream;
          // this.audioElement.play();
          this.localStream.getTracks().forEach(track => this.pc.addTrack(track, this.localStream));
        }.bind(this))
        .then(function() {
          return this.pc.createAnswer();
        }.bind(this))
        .then(function(answer) {
          return this.pc.setLocalDescription(answer);
        }.bind(this))
        .then(function() {
          this.canvasService.sendRtcDetails("video-answer", "admin", this.userID, JSON.stringify(this.pc.localDescription))
        }.bind(this))
        .catch(error => {
          console.log("Error" + error);
        });
      } else {
        var candidate = new RTCIceCandidate(JSON.parse(content));

        this.pc.addIceCandidate(candidate)
          .catch(Error => {
            console.log(Error => console.log(Error))
          });
      }
    }
  }

  startRTC(){
    this.audioElement = document.createElement("audio");
    var remoteAudioEl = document.createElement("audio");
    this.pc = new RTCPeerConnection(SERVERS, DEFAULT_CONSTRAINTS);
    this.pc.onicecandidate = function(event){
      console.log("Guest Ice candidate changed" + event);
      this.canvasService.sendRtcDetails("new-ice-candidate", "admin", this.userID, JSON.stringify(event.candidate));
    }.bind(this);
    this.pc.ontrack = function(event){
      console.log("Guest track added" + event.streams[0]);
      remoteAudioEl.src = URL.createObjectURL(event.streams[0]);
      remoteAudioEl.play();
    };
    this.pc.onnegotiationneeded = function(event){
      console.log("Guest Negotiation needed");
      this.pc.createOffer().then(function(offer) {
        console.log("server local description " + JSON.stringify(this.pc.localDescription));
      }.bind(this))
      .catch(error => {
        console.log(error);
      });
    }.bind(this);
    // this.canvasService.startRtc(this.userID);
    // this.canvasService.monitorRtcDetails(this);
  }

  fileSelected($event){
    var files = $event.target.files;
    var file = files[0];
    if(file){
      var reader = new FileReader();
      reader.onload = function(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.http.post('https://v2.convertapi.com/convert/pptx/to/jpg?Secret=q4f8Fb0VcumbXzA1', {
        "Parameters": [
          {
              "Name": "File",
              "FileValue": {
                  "Name": "my_file.pptx",
                  "Data": btoa(binaryString)
              }
          },
          {
              "Name": "StoreFile",
              "Value": true
          }
      ]
      })
      .subscribe(
        res => {
          console.log(res);
          for(var i = 0; i < res.Files.length; i++){
            console.log(res.Files[i].Url);
            this.detailsArray.push({
              url: res.Files[i].Url
            });
          }
          this.sendPPTimage();
        },
        err => {
          console.log("Error occured");
        }
      );


    }.bind(this);
    reader.readAsBinaryString(file);
    } 
  }

  sendPPTimage(){
    var tempCanvas = document.createElement("canvas");
    var tempctx = tempCanvas.getContext("2d");
    var img = new Image();
    img.onload = function(){
      this.index++;
      tempCanvas.width = 500;
      tempCanvas.height = 400;
      tempctx.drawImage(img, 0, 0, img.width,    img.height,     // source rectangle
        0, 0, tempCanvas.width, tempCanvas.height);
      this.canvasService.savePPTimages(this, "test", tempCanvas.toDataURL('image/png', 0.1), this.index);
      if(this.index < this.detailsArray.length){
        this.sendPPTimage();
      }
    }.bind(this);
    img.src = this.detailsArray[this.index].url;
    img.crossOrigin = "Anonymous";
  }

}
