import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { GuestComponent } from "./guest/guest.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

const routes : Routes = [
    {path:"", component: DashboardComponent},
    {path:"dashboard", component: DashboardComponent},
    {path:"home", component: HomeComponent},
    {path:"login", component: LoginComponent},
    {path:"guest", component: GuestComponent},
    {path:"**", component: DashboardComponent}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports : [RouterModule]
})

export class AppRoutingModule {}