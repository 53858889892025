import { Component, OnInit, NgZone} from '@angular/core';
import { AuthenticationService } from "../services/authentication.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ AuthenticationService ]
})
export class LoginComponent implements OnInit {

  isSignUp = false;
  isLogin = true;
  isForgotPswd = false;
  signUpEmail = "";
  signUpPassword = "";
  signUpRepeatPassword = "";
  signUpFirstname = "";
  signUpLastname = "";
  isSignUpError = false;
  isLoading = false;
  signUpErrorMsg = "password mismatch";
  signInEmail = "";
  signInPassword = "";
  isSignInError = false;
  signInErrorMsg = "";
  resetEmail = "";
  resetErrorMsg = "";
  isResetError = false;
  isResetSuccess = false;
  currentYear = (new Date()).getFullYear();

  constructor(private service: AuthenticationService, private router: Router, private zone:NgZone) { }

  ngOnInit() {
    
  }

  setRegister(){
    this.isSignUp = true;
    this.isLogin = false;
    this.isForgotPswd = false;
  }

  setLogin(){
    this.isLogin = true;
    this.isForgotPswd = false;
    this.isSignUp = false;
  }

  setForgotPswd(){
    this.isForgotPswd = true;
    this.isLogin = false;
    this.isSignUp = false;
  }

  setHome(){
  }

  setPrivacy(){
  }

  setTerms(){
  }

  googleLogin(){
    this.service.googleLogin(this);
  }

  fbLogin(){
    this.service.signInWithFacebook(this);
  }

  signUpWithEmailPassword(){
    this.isLoading = true;
    this.isSignUpError = false;
    this.service.signUpWithEmailPassword(this,this.signUpEmail,this.signUpPassword,this.signUpFirstname, this.signUpLastname);
  }

  signInWithEmailPassword(){
    this.isSignInError = false;
    this.isLoading = true;
    this.service.signInWithEmailPassword(this,this.signInEmail,this.signInPassword);
  }

  resetPassword(){
    this.isLoading = true;
    this.isResetError = false;
    this.isResetSuccess = false;
    this.service.resetPassword(this,this.resetEmail);
  }

  resetSuccess(){
    this.isLoading = false;
    this.isResetSuccess = true;
  }

  goToDashboard(){
    this.zone.run(() => this.router.navigateByUrl("/dashboard"));
  }

  signUpFail(error){
    this.isLoading = false;
    this.isSignUpError = true;
    this.signUpErrorMsg = error;
  }

  signInFail(error){
    this.isLoading = false;
    this.isSignInError = true;
    this.signInErrorMsg = "Google sign error: " + error;
  }

  resetFail(error){
    this.isLoading = false;
    this.isResetError = true;
    this.resetErrorMsg = "Error: There is no record corresponding to this email id. Please try again.";
  }

  checkRepeatPassword(){
    if(this.signUpRepeatPassword !== "" && this.signUpPassword !== ""){
      if(this.signUpPassword === this.signUpRepeatPassword){
        this.isSignUpError = false;
      }else{
        this.signUpErrorMsg = "password mismatch"
        this.isSignUpError = true;
      }
    }else{
      this.isSignUpError = false;
    }
  }

}
