import { Injectable,  } from '@angular/core';
import { AngularFireDatabase, AngularFireAction } from '@angular/fire/database';
import { finalize, map, switchMap } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { element } from 'protractor';
import { access } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  size$: BehaviorSubject<string|null>;
  canvasComponentData: any = {
    sessionHeaderKey: '',
    userUID: '',
    fromDashboard: false,
    shareCode: '',
    sessionType: ''
  };
  videoObject: any = {
    hasVideoUrl: false,
    videoUrl: ''
  };
  shareData: Observable<any[]>;
  count: any = 0;
  flag2: any = 0;
  flag3: any = 0;
  flag4: any = 0;
  flag5: any = 0;
  canvasViewerDbKey = '';
  rtcRemoveFlag = true;
  elementLength: any = 0;
  deleteDataCount: any = 0;
  canvasCoordinatesKey: any[] = new Array();

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  saveCanvasData(startpoint, currentpoint, type, isFill, lineWidth, canvasColor, sessionHeaderKey, pageKey, userUID) {
    const itemsRef = this.db.list('WhiteboardData/' + sessionHeaderKey + '/' + pageKey);
    itemsRef.push({
      startpointX: startpoint.x,
      startpointY: startpoint.y,
      currentpointX: currentpoint.x,
      currentpointY: currentpoint.y,
      type: type,
      isFill: isFill,
      lineWidth: lineWidth,
      canvasColor: canvasColor,
      userUID: userUID
    }).then(result => {
      if (result !== null) {
        // console.log("success");
      } else {
        console.log('failure');
      }
    });
  }

  clearCanvas() {
    const itemsRef = this.db.list('canvasDatas');
    itemsRef.remove();
  }

  getCanvasData(obj) {
    // this.db.list("canvasDatas").snapshotChanges().subscribe(datas => {
    //   console.log("snapshot changes" + datas);
    // });

    // this.db.list("canvasDatas").valueChanges().subscribe(datas => {
    //   console.log("changed");
    //   datas.forEach(el => {
    //     console.log(el);
    //   })
    // });

    this.db.list('canvasDatas').stateChanges().subscribe(datas => {
      // console.log(datas.type);
      if (datas.type === 'child_removed') {
        obj.clearCanvas();
      } else {
        console.log('getting');
        // console.log(datas.payload.val());
        obj.draw(datas.payload.val()['startpointX'],
                datas.payload.val()['startpointY'],
                datas.payload.val()['currentpointX'],
                datas.payload.val()['currentpointY'],
                datas.payload.val()['type'],
                datas.payload.val()['isFill'],
                datas.payload.val()['lineWidth'],
                datas.payload.val()['canvasColor']);
      }
    });

    // this.db.list("canvasDatas").snapshotChanges().pipe(
    //   map(changes =>{
    //     console.log("changes" + changes);
    //     changes.map(c => ({
    //        key: c.payload.key, ...c.payload.val()
    //       }));
    //   })
    // ).subscribe(item => {
    //   console.log("subscribe" + item);
    // });
  }

  intiateSession(obj, userUID, shareCode, backgroundType, backgroudColor, sessionType, sessionName) {
    const itemsRef = this.db.list('SessionHeader');
    itemsRef.push({
      createdBy: userUID,
      createdDate: this.getDate(),
      sessionName: sessionName,
      shareCode: shareCode,
      sessionType: sessionType,
      backgroundType: backgroundType,
      backgroudColor: backgroudColor,
      sessionIsRecorded: true,
      maxViewers: 20,
      maxPages: 5,
      currentPage: ''
    }).then(result => {
      if (result !== null) {
        console.log('Sessionheader initiatiated');
        // obj.setSessionHeaderKey(result.key);
        this.db.list('CanvasViewers/' + result.key).push({
          createdDate: this.getDate(),
          userUID: userUID,
          userAccessType: 'admin',
          requestSent: true
        }).then( viewerResult => {
          if (viewerResult !== null) {
            if (sessionType === 'whiteboard') {
              const pagesRef = this.db.list('WhiteboardPages/' + result.key);
              pagesRef.push({
                createdDate: this.getDate(),
                pageName: '',
                pageData: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
              }).then(pagesResult => {
                if (pagesResult !== null) {
                  console.log('Pages details stored');
                  // obj.setPageKey(pagesResult.key);
                  // obj.monitorPageData(result.key);
                  // obj.monitorCanvasCoordinates(result.key, 1);
                  // obj.pushSnapshot();
                  // obj.onResize();
                  // obj.getCanvasMessages();
                  obj.goToCanvas(result.key, shareCode, sessionType);
                } else {
                  console.log('failure in creating page');
                }
              });
            } else {
              obj.sendPPTimageToServer(result.key);
            }
          } else {
            console.log('failure in setting user');
          }
        });
      } else {
        console.log('failure in creating session header');
      }
    });
  }

  createPPTsession(obj, userUID, shareCode) {
    const itemsRef = this.db.list('SessionHeader');
    itemsRef.push({
      createdBy: userUID,
      createdDate: this.getDate(),
      sessionName: '',
      shareCode: shareCode,
      sessionType: 'ppt',
      backgroundType: '',
      backgroudColor: '',
      sessionIsRecorded: true,
      maxViewers: 20,
      maxPages: 5
    }).then(result => {
      if (result !== null) {
        this.db.list('CanvasViewers/' + result.key).push({
          joinedDate: this.getDate(),
          userUID: userUID,
          userAccessType: 'admin',
          requestSent: true
      }).then( resultViewer => {
        if (resultViewer !== null) {
          obj.sendPPTimageToServer(result.key);
        } else {
          console.log('sending ppt to server failed');
        }
      });
      } else {
        console.log('ppt header creation falied');
      }
    });
  }


  getDate() {
    const d = new Date();
    const year = d.getUTCFullYear();
    const month = d.getUTCMonth() + 1;
    const day = d.getUTCDate() + 1;
    const hour = d.getUTCHours() + 1;
    const minute = d.getUTCMinutes() + 1;
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  sendPageData(sessionHeaderKey, pageKey, url, isWhiteboard) {
    if (isWhiteboard) {
      const itemsRef = this.db.list('WhiteboardPages/' + sessionHeaderKey);
            itemsRef.update(pageKey, {
              pageData: url
             }).then( () => {
                // console.log("update success ");
             }).catch(error => {
               console.log(error);
             });
    } else {
      const itemsRef = this.db.list('PPTimages/' + sessionHeaderKey);
      itemsRef.update(pageKey, {
        pageData: url
       }).then( () => {
          console.log('update success ');
       }).catch(error => {
         console.log(error);
       });
    }
  }

  monitorPageData(sessionHeaderKey, isWhiteboard) {
    console.log(sessionHeaderKey);
    this.size$ = new BehaviorSubject(null);
    if (isWhiteboard) {
      return this.size$.pipe(
        switchMap(size =>
          this.db.list('/WhiteboardPages/' + sessionHeaderKey).snapshotChanges())
      );
    } else {
      return this.size$.pipe(
        switchMap(size =>
          this.db.list('/PPTimages/' + sessionHeaderKey).snapshotChanges())
      );
    }
  }

  createNewPage(obj, sessionHeaderKey) {

    const pagesRef = this.db.list('WhiteboardPages/' + sessionHeaderKey);
    pagesRef.push({
      createdDate: this.getDate(),
      pageName: '',
      pageData: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }).then(pagesResult => {
      if (pagesResult !== null) {
        console.log('pages created');
        obj.setPageKey(pagesResult.key);
        this.monitorCanvasCoordinates(obj, sessionHeaderKey, pagesResult.key);
        obj.pushNewPageSnapshot();
        this.setCurrentPage(sessionHeaderKey, pagesResult.key);
      }
    });

  }

  getSessionHistory(obj, userUID) {
    this.db.list('/SessionHeader', ref => ref.orderByChild('createdBy').equalTo(userUID)).stateChanges()
                .subscribe(datas => {
                  if (datas.type === 'child_removed') {
                    obj.onSessionRemoved(datas.payload.key);
                  } else if (datas.type === 'child_added') {
                    if (datas.payload.val()['sessionType'] === 'whiteboard') {
                      this.db.list('/WhiteboardPages/' + datas.payload.key, ref => ref.limitToFirst(1)).stateChanges()
                        .subscribe(pagesData => {
                          if ( pagesData.payload.val()['pageData'] !== '' && pagesData.payload.val()['pageData'] !== 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') {
                            obj.setPagesDataArray(datas.payload.key, pagesData.payload.val()['pageData'], datas.payload.val()['shareCode'], pagesData.type, datas.payload.val()['sessionType'], datas.payload.val()['sessionName'], datas.payload.val()['createdDate']);
                          }
                        });
                    } else {
                      this.db.list('/PPTimages/' + datas.payload.key, ref => ref.limitToFirst(1)).stateChanges()
                      .subscribe(pagesData => {
                        obj.setPagesDataArray(datas.payload.key, pagesData.payload.val()['pageData'], datas.payload.val()['shareCode'], pagesData.type, datas.payload.val()['sessionType'], datas.payload.val()['sessionName'], datas.payload.val()['createdDate']);
                      });
                    }
                  }
                });
  }

  getJoinedSessionsHistory(obj, userUID) {
    this.db.list('/SessionHeader').stateChanges()
                .subscribe(data => {
                  if (data.type === 'child_removed') {
                    obj.onJoinedSessionRemoved(data.payload.key);
                  } else if (data.type === 'child_added' || data.type === 'value') {
                    this.db.list('/CanvasViewers/' + data.payload.key, ref => ref.orderByChild('userUID').equalTo(userUID))
                            .snapshotChanges().forEach( element => {
                              if (element.length > 0 && element[0].payload.val()['userAccessType'] !== 'admin' && element[0].type === 'value' ) {
                                if (data.payload.val()['sessionType'] === 'whiteboard') {
                                  this.db.list('/WhiteboardPages/' + data.payload.key, ref => ref.limitToFirst(1)).stateChanges()
                                    .subscribe(pagesData => {
                                        if ( pagesData.payload.val()['pageData'] !== '' && pagesData.payload.val()['pageData'] !== 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7') {
                                          obj.setJoinedSessionsArray(data.payload.key, pagesData.payload.val()['pageData'], data.payload.val()['shareCode'], pagesData.type, data.payload.val()['sessionType'], data.payload.val()['sessionName'], element[0].payload.val()['joinedDate']);

                                        }
                                    });
                                } else {
                                  this.db.list('/PPTimages/' + data.payload.key, ref => ref.limitToFirst(1)).stateChanges()
                                  .subscribe(pagesData => {
                                    obj.setJoinedSessionsArray(data.payload.key, pagesData.payload.val()['pageData'], data.payload.val()['shareCode'], pagesData.type, data.payload.val()['sessionType'], data.payload.val()['sessionName'], element[0].payload.val()['joinedDate']);
                                });
                                }
                              }
                              if (element.length > 0) {

                              }
                      });
                  }
    });
  }

  setCanvasComponentData(sessionHeaderKey, userUID, fromDashboard, shareCode, sessionType) {
    this.canvasComponentData.sessionHeaderKey = sessionHeaderKey;
    this.canvasComponentData.userUID = userUID;
    this.canvasComponentData.fromDashboard = fromDashboard;
    this.canvasComponentData.shareCode = shareCode;
    this.canvasComponentData.sessionType = sessionType;
  }

  getCanvasComponentData() {
    return this.canvasComponentData;
  }

  setResumeSession(obj, sessionHeaderKey, isWhiteboard) {
    let firsCall = true;
    if (isWhiteboard) {
      this.db.list('/WhiteboardPages/' + sessionHeaderKey).stateChanges()
            .subscribe(pagesData => {
              if (pagesData.type === 'child_added') {
                if (firsCall) {
                  obj.setResumeCanvasSession(pagesData.payload.key);
                  firsCall = false;
                }
                obj.pushPageKeys(pagesData.payload.key);
              }
            });
      } else {
        this.db.list('/PPTimages/' + sessionHeaderKey).stateChanges()
        .subscribe(pagesData => {
          if (pagesData.type === 'child_added') {
            if (firsCall) {
              obj.setResumePowerpointSession(pagesData.payload.key, pagesData.payload.val()['originUrl']);
              obj.pushOriginPPTsnapshot(pagesData.payload.val()['originUrl']);
              firsCall = false;
            }
          }
        });
      }
  }

  monitorCanvasCoordinates(obj, sessionHeaderKey, pageKey) {

      this.db.list('WhiteboardData/' + sessionHeaderKey + '/' + pageKey).stateChanges()
          .subscribe(coordinates => {
              // console.log("coordinates type " + coordinates.type);
              if (coordinates.type !== 'child_removed') {
                this.canvasCoordinatesKey.push(coordinates.payload.key);
                obj.remoteDraw(coordinates.payload.val()['startpointX'], coordinates.payload.val()['startpointY'],
                              coordinates.payload.val()['currentpointX'], coordinates.payload.val()['currentpointY'],
                              coordinates.payload.val()['type'], coordinates.payload.val()['isFill'],
                              coordinates.payload.val()['lineWidth'], coordinates.payload.val()['canvasColor'], coordinates.payload.val()['userUID'], pageKey);
              } else if (coordinates.type === 'child_removed') {
                this.deleteDataCount++;
                if (this.deleteDataCount == this.canvasCoordinatesKey.length) {
                  obj.onClearCanvasByPageSuccess(pageKey);
                }
              }

          });
  }

  checkShareCode(obj, shareCode) {
    this.db.list('/SessionHeader', ref => ref.orderByChild('shareCode').equalTo(shareCode)).snapshotChanges().forEach(element => {
                  if (element.length > 0) {
                    if (element[0].type === 'value' || element[0].type === 'child_added') {
                      obj.goToCanvas(element[0].key, shareCode, element[0].payload.val()['sessionType']);
                    }
                  } else {
                    console.log('fail');
                    obj.removeLoader();
                  }

                });
  }

  checkAccessType(obj, sessionHeaderKey, userUID) {
    this.db.list('/CanvasViewers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID))
                .snapshotChanges().forEach( element => {
                    console.log('Canvas viewers length: ' + element.length);
                    if (element.length == 0) {
                      this.db.list('/CanvasViewers/' + sessionHeaderKey).push({
                        joinedDate: this.getDate(),
                        userUID: userUID,
                        userAccessType: 'viewer',
                        requestSent: false
                      });
                    } else {
                      this.canvasViewerDbKey = element[0].key;
                      if (element[0].payload.val()['userAccessType'] === 'admin') {
                        if (element[0].type === 'value') {

                          obj.setAccessType(true, false);
                        }
                      } else if (element[0].payload.val()['userAccessType'] === 'viewer') {
                        if (element[0].payload.val()['requestSent']) {
                          obj.setAccessType(false, true, true);
                        } else {
                          obj.setAccessType(false, true, false);
                        }
                      } else {
                        obj.setAccessType(false, false, false);
                      }
                    }
                });
  }

  removeCollaboratorPermission(obj, sessionHeaderKey, userUID) {
    this.db.list('/CanvasViewers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID))
                .snapshotChanges().forEach( element => {
                  if (element.length == 0) {
                    this.checkAccessType(obj, sessionHeaderKey, userUID);
                  } else {
                    if (element[0].type === 'value') {
                      if (element[0].payload.val()['userAccessType'] === 'collaborator') {
                        this.db.list('/CanvasViewers/' + sessionHeaderKey).update(element[0].key, {
                          userAccessType: 'viewer',
                          requestSent: false
                        }).then(() => {
                          this.checkAccessType(obj, sessionHeaderKey, userUID);
                        }).catch( error => {
                          console.log('collaborator permission changing error ' + error);
                        });
                      } else {
                        this.checkAccessType(obj, sessionHeaderKey, userUID);
                      }
                    }
                  }
                });
  }

  removePermission(sessionHeaderKey, userUID) {
    // console.log("in remove permission");
    this.db.list('/CanvasViewers/' + sessionHeaderKey).stateChanges()
        .subscribe(data => {
          if (data.type === 'child_added' && data.payload.val()['userUID'] !== userUID) {
            this.db.list('/CanvasViewers/' + sessionHeaderKey).update(data.key, {
              userAccessType: 'viewer',
              requestSent: false
            }).then(() => {
              // console.log("permission successfully changed");
            }).catch( error => {
              console.log('updation error ' + error);
            });
          }
        });
  }

  saveCanvasMessages(obj, sessionHeaderKey, userUID, msg, type) {
    this.db.list('CanvasTextMessages/' + sessionHeaderKey).push({
      userUID: userUID,
      message: msg,
      type: type
    }).then(result => {
      if (result !== null) {
        // console.log("text message sent");
        if (type === 'request') {
          this.db.list('CanvasViewers/' + sessionHeaderKey).update(
            this.canvasViewerDbKey, {
              requestSent: true
            }
          ).then(() => {
            // console.log("successfully request sent");
          }).catch( error => {
            console.log(error);
          });
        } else if (type === 'voice') {
          this.setVoiceRequestStatus(sessionHeaderKey, userUID);
        }
      }
    });
  }

  setVoiceRequestStatus(sessionHeaderKey, userUID) {
    this.db.list('RTCusers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID)).snapshotChanges()
    .forEach(element => {
      if (element.length > 0 && element[0].type === 'value') {
        this.db.list('RTCusers/' + sessionHeaderKey).update(element[0].key, {
          voiceRequest: true
        }).then(() => {
            });
      }
    });
  }

  getCanvasMessages(obj, sessionHeaderKey) {
    this.db.list('CanvasTextMessages/' + sessionHeaderKey).stateChanges()
            .subscribe( data => {

              if ( data.type === 'child_removed' ) {
                obj.removeRequesItem(data.payload.key);
              } else {
                this.db.list('UserDetails', ref => ref.orderByChild('userUID').equalTo(data.payload.val()['userUID']))
                        .stateChanges().subscribe( userDatas => {
                          if (userDatas.type === 'child_added') {
                            obj.pushCanvasMessageArray(data.payload.val()['message'], data.payload.val()['type'], userDatas.payload.val()['firstName'], data.payload.val()['userUID'], userDatas.payload.val()['imageData'], data.payload.key);
                          }
                        });
              }
            });
  }

  onRequestAccept(userUID, messagekey, sessionHeaderKey, requestAccepted: boolean) {
    this.db.list('/CanvasViewers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID))
            .stateChanges().subscribe( data => {
              // console.log("data type" + data.type);
              // console.log("requestAccepted" + requestAccepted);
              if ( data.type === 'child_added' ) {
                if (requestAccepted) {
                  this.db.list('/CanvasViewers/' + sessionHeaderKey).update(data.payload.key, {
                    userAccessType: 'collaborator'
                  }).then(() => {
                    this.db.list('CanvasTextMessages/' + sessionHeaderKey).remove(messagekey)
                    .then( () => {
                      // console.log("message delete successfully");
                    }).catch( error => {
                      console.log(error);
                    });
                  }).catch( error => {
                    console.log(error);
                  });
                } else {
                  this.db.list('/CanvasViewers/' + sessionHeaderKey).update(data.payload.key, {
                    requestSent: false
                  }).then(() => {
                    this.db.list('CanvasTextMessages/' + sessionHeaderKey).remove(messagekey)
                    .then( () => {
                      // console.log("message delete successfully");
                    }).catch( error => {
                      console.log(error);
                    });
                  }).catch( error => {
                    console.log(error);
                  });
                }
              }
            });
  }

  onVoiceRequestAccept(userUID, messagekey, sessionHeaderKey, requestAccepted: boolean) {
    this.db.list('/RTCusers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID))
        .snapshotChanges().subscribe( data => {
          if (data.length > 0) {
            if (data[0].type === 'value') {
              if (requestAccepted) {
                this.db.list('CanvasTextMessages/' + sessionHeaderKey).remove(messagekey)
                      .then( () => {
                        this.db.list('/RTCusers/' + sessionHeaderKey).update(data[0].payload.key, {
                          voiceRequest: false
                        }).then(() => {
                          this.changeUserMic(data[0].payload.key, sessionHeaderKey, true);
                        }).catch(error => {
                          console.log(error);
                        });
                      }).catch( error => {
                        console.log(error);
                });
              } else {
                this.db.list('/RTCusers/' + sessionHeaderKey).update(data[0].payload.key, {
                  voiceRequest: false
                }).then(() => {
                  this.db.list('CanvasTextMessages/' + sessionHeaderKey).remove(messagekey)
                  .then( () => {
                    // console.log("message delete successfully");
                  }).catch( error => {
                    console.log(error);
                  });
                }).catch( error => {
                  console.log(error);
                });
              }
            }
          }
        });
  }

  sendRtcDetails(type, target, userUID, content, sessionHeaderKey) {
    if (content !== 'null') {
      this.db.list('/RTCdetails/' + sessionHeaderKey).push({
        type: type,
        target: target,
        userUID: userUID,
        content: content
      });
    }
  }

  monitorRtcDetails(obj, sessionHeaderKey) {
    this.db.list('RTCdetails/' + sessionHeaderKey).stateChanges().subscribe( datas => {
      if (datas.type !== 'child_removed') {
        // console.log("creating answer");
        obj.createAnswerWebRTC(datas.payload.val()['type'], datas.payload.val()['target'], datas.payload.val()['content'], datas.payload.val()['userUID']);
      }
    });
  }

  startRtc(obj, userUID, sessionHeaderKey) {
    this.db.list('RTCusers/' + sessionHeaderKey).push({
      userUID: userUID,
      isMicEnabled: false,
      voiceRequest: false
    }).then(() => {
      this.monitorRTCusers(obj, sessionHeaderKey);
      this.monitorRtcDetails(obj, sessionHeaderKey);
      this.monitorRTCusersRemoval(obj, sessionHeaderKey);
    });
  }

  removeRTCUser(obj, userUID, sessionHeaderKey) {
    this.db.list('RTCusers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID)).snapshotChanges()
      .forEach(element => {
        if (element.length > 0 && element[0].type === 'value') {
          this.db.list('RTCusers/' + sessionHeaderKey).remove(element[0].key)
              .then(() => {
                this.removeRTCdetails(obj, userUID, sessionHeaderKey);
              });
        } else if (element.length == 0) {
          this.removeRTCdetails(obj, userUID, sessionHeaderKey);
        }
      });
  }

  removeRTCdetails(obj, userUID, sessionHeaderKey) {
    this.db.list('RTCdetails/' + sessionHeaderKey).snapshotChanges().forEach(element => {
      if (this.rtcRemoveFlag) {
        if (element.length > 0) {
          console.log('hello world');
          const updates = {};
          for (let i = 0; i < element.length; i++) {
            if (element[i].payload.val()['userUID'] === userUID || element[i].payload.val()['target'] === userUID) {
              updates[element[i].key] = null;
            }
          }
          this.db.list('RTCdetails').update(sessionHeaderKey, updates).then(() => {
            this.startRtc(obj, userUID, sessionHeaderKey);
          });
        } else if (element.length == 0) {
          console.log('world hello');
          this.startRtc(obj, userUID, sessionHeaderKey);
        }
      }
      this.rtcRemoveFlag = false;
    });
  }

  monitorRTCusersRemoval(obj, sessionHeaderKey) {
    this.db.list('RTCusers/' + sessionHeaderKey).stateChanges()
      .subscribe(element => {
        console.log('child type ' + element.type);
        if (element.type === 'child_removed') {
          obj.removeRTCuser(element.payload.val()['userUID']);
        } else if (element.type === 'child_changed') {
          obj.changeMicStatus(element.payload.val()['userUID'], element.payload.val()['isMicEnabled'], element.payload.val()['voiceRequest']);
        }
      });
  }

  monitorRTCusers(obj, sessionHeaderKey) {

    this.db.list('RTCusers/' + sessionHeaderKey).snapshotChanges().forEach(element => {
      if (element.length >= this.elementLength) {
        this.elementLength = element.length;
        if (element[element.length - 1].type === 'child_added') {
          obj.receiveVoiceCall(element[element.length - 1].payload.val()['userUID']);
        } else if (element[element.length - 1].type === 'child_changed') {

        } else {
          for (let i = 0; i < element.length; i++) {
            if (element[i].type === 'value') {
              obj.startVoiceChat(element[i].payload.val()['userUID'], element[i].payload.val()['voiceRequest']);
            }
          }
        }
      }
    }).then(() => {
      console.log('reading finished');
    });
  }

  removeCompleteRTC(obj, sessionHeaderKey) {
    this.db.list('RTCdetails/' + sessionHeaderKey).remove()
      .then(() => {
        // console.log("rtc details deleting finished");
        this.db.list('RTCusers/' + sessionHeaderKey).remove()
          .then(() => {
            // console.log("rtc users deleting finished");
            // this.monitorRTCusers(obj, sessionHeaderKey);
            // this.monitorRtcDetails(obj, sessionHeaderKey);
          }).catch(error => {
            console.log('Removing rtc deatils ' + error);
          });
      }).catch(error => {
        console.log('Removing rtc users failed ' + error);
      });
  }

  savePPTimages(sessionHeaderKey, url) {
    this.db.list('/PPTimages/' + sessionHeaderKey).push({
      pageData: url,
      originUrl: url
    }).then( data => {
      console.log('successful' + data);
    });
  }

  deletePage(obj, sessionHeaderKey, pageKey) {
    this.db.list('WhiteboardPages/' + sessionHeaderKey + '/' + pageKey).remove()
          .then(() => {
            // console.log("Page Deleted successfully");
            this.db.list('WhiteboardData/' + sessionHeaderKey + '/' + pageKey).remove()
            .then(() => {
              // console.log("Pages data deleted successfully");
              obj.onPageDeleteSuccess();
            }).catch(error => {
              console.log('error deleting page details ' + error);
            });
          })
          .catch(error => {
            console.log('error deleting page' + error);
          });
  }

  monitorPageRemoval(obj, sessionHeaderKey) {
    this.db.list('WhiteboardPages/' + sessionHeaderKey).stateChanges()
                .subscribe(datas => {
                  if (datas.type === 'child_removed') {
                    obj.onPageDeleteSuccessNonAdmin(datas.payload.key);
                  }
                });
  }

  removeSessionService(obj, sessionHeaderKey, type) {
    this.db.list('SessionHeader/' + sessionHeaderKey).remove()
      .then(() => {
          this.db.list('RTCdetails/' + sessionHeaderKey).remove()
            .then(() => {
              this.db.list('RTCusers/' + sessionHeaderKey).remove()
                .then(() => {
                  this.db.list('CanvasTextMessages/' + sessionHeaderKey).remove()
                    .then(() => {
                      this.db.list('CanvasViewers/' + sessionHeaderKey).remove()
                        .then(() => {
                          this.db.list('WhiteboardData/' + sessionHeaderKey).remove()
                            .then(() => {
                                if (type === 'whiteboard') {
                                  this.db.list('WhiteboardPages/' + sessionHeaderKey).remove()
                                    .then(() => {
                                      obj.removeLoader();
                                    }).catch(error => {
                                      console.log('Error deleting whiteboard data ' + error);
                                    });
                                } else {
                                  this.db.list('/PPTimages/' + sessionHeaderKey).remove()
                                    .then(() => {
                                      obj.removeLoader();
                                    }).catch(error => {
                                      console.log('Error deleting ppt images ' + error);
                                    });
                                }
                            }).catch(error => {
                              console.log('Error deleting whitebord data ' + error);
                            });
                        }).catch(error => {
                          console.log('Error deleting canvas viewers ' + error);
                        });
                    }).catch(error => {
                      console.log('Error deleting canvas messages ' + error);
                    });
                }).catch(error => {
                  console.log('Error in deleting rtc users ' + error);
                });
            }).catch(error => {
              console.log('Error in deleting rtc details ' + error);
            });
      }).catch(error => {
        console.log('Error in deleting session ' + error);
      });
  }

  monitorSessionActivity(obj, sessionHeaderKey, isWhiteboard) {
    this.db.list('SessionHeader/' + sessionHeaderKey).stateChanges()
            .subscribe(data => {
              if (data.type === 'child_removed') {
                obj.goToDashboard();
              } else if (data.type === 'child_changed') {
                if (isWhiteboard) {
                  obj.checkCurrentPage(data.payload.val(), '');
                } else {
                  console.log('changed' + data.payload.val());
                  this.db.list('PPTimages/' + sessionHeaderKey + '/' + data.payload.val()).stateChanges()
                  .subscribe(values => {
                    if (values.type === 'child_added') {
                      obj.checkCurrentPage(data.payload.val(), values.payload.val());
                    }
                  });
                }
              }
    });
  }

  removeJoinedSession(obj, sessionHeaderKey, userUID) {
    this.db.list('/CanvasViewers/' + sessionHeaderKey, ref => ref.orderByChild('userUID').equalTo(userUID))
          .remove().then(() => {
            obj.onJoinedSessionRemoved(sessionHeaderKey);
            obj.removeLoader();
          }).catch(error => {
            console.log('Error in removing from session ' + error);
          });
  }

  updateSessionName(sessionHeaderKey, sessionName) {
    const itemsRef = this.db.list('SessionHeader');
    itemsRef.update(sessionHeaderKey, {
      sessionName: sessionName
      }).then( () => {
        // console.log("name update success ");
      }).catch(error => {
        console.log(error);
      });
  }

  updateUserName(obj, key, userName) {
    this.db.list('UserDetails/').update(key, {
      firstName: userName
    }).then(() => {
    }).catch(error => {
      obj.showSnackbar(error);
    });
  }

  saveSessionVideo(obj, file) {
    const filePath = '/videos/' + Math.floor((Math.random() * 100000) + 1) + '.webm';
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.percentageChanges().subscribe(value => {
      obj.setVideoUploadPercentage(value.toFixed(0));
    });
    task.then(() => {
      fileRef.getDownloadURL().subscribe(params => {
        obj.onVideoUploadSuccess(params);
        console.log('video url' + params);
      });
    })
    .catch(error => {
      console.log('fail to upload' + error);
    } );
  }

  saveSessionVideoUrl(obj, sessionHeaderKey, videoUrl, userUID, thumbnail) {
    const pagesRef = this.db.list('VideoSessions');
              pagesRef.push({
                createdDate: this.getDate(),
                videoUrl: videoUrl,
                sessionHeaderKey: sessionHeaderKey,
                createdBy: userUID,
                thumbnail: thumbnail
              }).then(pagesResult => {
                if (pagesResult !== null) {
                  obj.onSavingVideoSessionSuccess();
                } else {
                  console.log('Failed to save video sesssion url');
                }
              });
  }

  saveThumbnail(obj, file) {
    const filePath = '/thumbnails/' + Math.floor((Math.random() * 100000) + 1) + '.png';
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.percentageChanges().subscribe(value => {
      obj.setVideoUploadPercentage(value.toFixed(0));
    });
    task.then(() => {
      fileRef.getDownloadURL().subscribe(params => {
        obj.onThumbnailUploadSuccess(params);
        console.log('thumbnail url ' + params);
      });
    })
    .catch(error => {
      console.log('fail to upload' + error);
    } );
  }

  saveUserImage(obj, file, key) {
    const filePath = '/userImages/' + Math.floor((Math.random() * 100000) + 1) + '.png';
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.percentageChanges().subscribe(value => {
      obj.setUserImageUploadProgress(value.toFixed(0));
    });
    task.then(() => {
      fileRef.getDownloadURL().subscribe(params => {
        obj.setUserImage(params);
        this.updateUserImage(obj, params, key);
      });
    })
    .catch(error => {
      obj.onImageUploadError(error);
    } );
  }

  updateUserImage(obj, imageUrl, key) {
    console.log('userdetails key: ' + key);
    this.db.list('UserDetails/').update(key, {
      imageData: imageUrl
    }).then(() => {
      obj.onImageUploadSuccess();
    }).catch(error => {
      obj.onImageUploadError(error);
    });
  }

  getRecordedVideosHistory(obj, userUID) {
    this.db.list('/VideoSessions', ref => ref.orderByChild('createdBy').equalTo(userUID)).stateChanges()
            .subscribe(datas => {
                if (datas.type === 'child_removed') {
                  obj.onVideoRemoved(datas.payload.key);
                } else {
                  this.db.list('SessionHeader/', ref => ref.orderByChild('createdBy').equalTo(userUID)).stateChanges()
                  .subscribe(sessionData => {
                    if (sessionData.type === 'child_added' || sessionData.type === 'value') {
                      if (sessionData.key === datas.payload.val()['sessionHeaderKey']) {
                        obj.setRecordedVideos(datas.type, datas.key, datas.payload.val()['createdDate'],
                                             sessionData.payload.val()['sessionName'], datas.payload.val()['sessionHeaderKey'],
                                             datas.payload.val()['videoUrl'], datas.payload.val()['thumbnail']);
                      }
                    }
                  });
                }
            });
  }

  setVideoUrlObject(videoUrl) {
    this.videoObject.hasVideoUrl = true;
    this.videoObject.videoUrl = videoUrl;
  }

  unsetVideoUrlObject() {
    this.videoObject.hasVideoUrl = false;
    this.videoObject.videoUrl = '';
  }

  checkVideoUrl(obj) {
    if (this.videoObject.hasVideoUrl) {
      obj.showVideoModal(this.videoObject.videoUrl);
    }
  }

  deleteVideo(obj, videoUrl, videoId) {
    this.storage.storage.refFromURL(videoUrl).delete()
        .then(() => {
          this.db.list('/VideoSessions/' + videoId).remove()
              .then(() => {
                obj.removeLoader();
                obj.showSnackbar('Video deleted successfully');
              }).catch(error => {
                console.log('Error' + error);
              });
        }).catch(error => {
          console.log('Error' + error);
        });
  }

  setCurrentPage(sessionHeaderKey, pageKey) {
    this.db.list('SessionHeader/').update(sessionHeaderKey, {
      currentPage: pageKey
    }).then(() => {
      console.log('successfully set current page');
    }).catch(error => {
      console.log(error);
    });
  }

  getRTCusers(obj, sessionHeaderKey) {
    this.db.list('RTCusers/' + sessionHeaderKey).stateChanges().subscribe( datas => {
      if (datas.type === 'child_added') {
        this.db.list('UserDetails', ref => ref.orderByChild('userUID').equalTo(datas.payload.val()['userUID']))
        .stateChanges().subscribe( userDatas => {
          if (userDatas.type === 'child_added') {
            obj.showRTCusers(datas.payload.val()['userUID'], userDatas.payload.val()['firstName'], userDatas.payload.val()['imageData'], datas.payload.val()['isMicEnabled'], datas.payload.key);
          }
        });
      } else if (datas.type === 'child_changed' || datas.type === 'child_removed') {
        obj.onRTCusersChanged(datas.type, datas.payload.key, datas.payload.val()['isMicEnabled']);
      }
    });
  }

  changeUserMic(key, sessionHeaderKey, isMicEnabled) {
    console.log('updating user mic');
    const itemsRef = this.db.list('RTCusers/' + sessionHeaderKey);
            itemsRef.update(key, {
              isMicEnabled: isMicEnabled
             }).then( () => {
             }).catch(error => {
               console.log(error);
             });
  }

  clearCanvasByPage(obj, sessionHeaderKey, pageKey) {
    if (this.canvasCoordinatesKey.length > 0) {
      for (let i = 0; i < this.canvasCoordinatesKey.length; i++) {
        this.db.list('WhiteboardData/' + sessionHeaderKey + '/' + pageKey).remove(this.canvasCoordinatesKey[i])
        .then( () => {
        }).catch( error => {
          console.log(error);
        });
      }
    } else {
      obj.stopLoading();
    }
  }

  resetCanvasCounts() {
    this.deleteDataCount = 0;
    this.canvasCoordinatesKey = [];
  }

}
