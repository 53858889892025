import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from "@angular/fire/database";
import { auth } from 'firebase';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { element } from '@angular/core/src/render3/instructions';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  item: Observable<any[]>;
  tokens: Observable<any[]>;
  refreshToken: any;

  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  checkAuthentication(obj){
    this.afAuth.authState.subscribe((auth) => {
      if(auth === null){
        obj.goToLogin(); 
      }else{
        this.refreshToken = auth.refreshToken;
        this.tokens = this.db.list('/UserDetails', ref => ref.orderByChild('userUID').equalTo(auth.uid)).snapshotChanges();
        this.tokens.forEach(element => {
            if(element[0].type === "value"){
              const itemsRef = this.db.list('UserDetails');
              itemsRef.update(element[0].key, { refreshToken: this.refreshToken })
                .then(() => {
                  console.log("succes");
                  obj.setUserUID(auth.uid);
                  obj.getDashboardData();
                  obj.checkForVideoUrl();
                  obj.setUserDetails(element[0].payload.val()["firstName"], element[0].payload.val()["emailID"], element[0].payload.val()["imageData"], element[0].key);
                  // obj.monitorRTCusers();
                })
                .catch(error => {
                  console.log(error);
                })
            }else{
              if(element[0].payload.val()["refreshToken"] !== this.refreshToken){
                this.signOut(obj);
              }
            }
          });
        if(auth["displayName"] === null){
          this.item = this.db.list('/UserDetails', ref => ref.orderByChild('userUID').equalTo(auth.uid)).valueChanges();
          this.item.forEach(element => {
            obj.setUsername(element[0].firstName);
          });
        }else{
          obj.setUsername(auth["displayName"]);
        }
      }
    });
  }

  googleLogin(obj){
    this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
      .then(auth=>{
        console.log(auth);
        var datas:Observable<any[]> = this.db.list('/UserDetails', ref => ref.orderByChild('userUID').equalTo(auth.user.uid)).snapshotChanges();
        
        datas.forEach(element => {
          if(element.length === 0){
            const itemsRef = this.db.list('UserDetails');
            itemsRef.push({ userUID: auth.user.uid,
              emailID: auth.user.email,
              firstName: auth.user.displayName,
              lastName: "",
              refreshToken: auth.user.refreshToken,
              imageData: auth.user.photoURL,
              createdDate: this.getDate(),
              toolBarPosition: "left"
             }).then(()=>{
              obj.goToDashboard();
             });
          }else{
            const itemsRef = this.db.list('UserDetails');
            itemsRef.update(element[0].key, { 
              userUID: auth.user.uid,
              emailID: auth.user.email,
              firstName: auth.user.displayName,
              lastName: "",
              refreshToken: auth.user.refreshToken,
              imageData: auth.user.photoURL,
             }).then(() => {
              obj.goToDashboard();
             }).catch(error => {
               console.log(error);
             })
          }
        });
        
      })
      .catch(error => {
        obj.signInFail(error);
      });
  }

  signInWithFacebook(obj) {
    this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider())
      .then(()=>{
        obj.goToDashboard();
      })
      .catch(error => {
        obj.signInFail(error);
      });
  }

  signUpWithEmailPassword(obj, email, password, firstname, lastname){
    this.afAuth.auth.createUserWithEmailAndPassword(email,password)
      .then(auth => {
        console.log(auth);
        const itemsRef = this.db.list("UserDetails");
        itemsRef.push(
          {
            userUID: auth.user.uid,
            emailID: auth.user.email,
            firstName: firstname,
            lastName: lastname,
            refreshToken: auth.user.refreshToken,
            imageData: "",
            createdDate: this.getDate(),
            toolBarPosition: "left"
          }
        ).then(result => {
          if(result !== null){
           obj.goToDashboard();
          }else{
            obj.signUpFail("Failed to register user");
          }
        });
      })
      .catch(error => {
        obj.signUpFail(error);
      });
  }

  signInWithEmailPassword(obj,email,password){
    this.afAuth.auth.signInWithEmailAndPassword(email,password)
      .then(auth => {
        // var datas:Observable<any[]> = this.db.list('/UserDetails', ref => ref.orderByChild('userUID').equalTo(auth.user.uid)).F();
        // datas.forEach(element => {
        //   if(element.length === 0){
        //     const itemsRef = this.db.list('UserDetails');
        //     itemsRef.push({ userUID: auth.user.uid,
        //       emailID: auth.user.email,
        //       firstName: auth.user.displayName,
        //       lastName: "",
        //       refreshToken: auth.user.refreshToken,
        //       imageData: auth.user.photoURL,
        //       createdDate: this.getDate(),
        //       toolBarPosition: "left"
        //      })
        //   }else{
        //     const itemsRef = this.db.list('UserDetails');
        //     itemsRef.update(element[0].key, { 
        //       userUID: auth.user.uid,
        //       emailID: auth.user.email,
        //       firstName: auth.user.displayName,
        //       lastName: "",
        //       refreshToken: auth.user.refreshToken,
        //       imageData: auth.user.photoURL,
        //       createdDate: this.getDate(),
        //       toolBarPosition: "left"
        //      })
        //   }
        // });
        obj.goToDashboard();
      })
      .catch(error => {
        obj.signInFail(error);
      })
  }

  signOut(obj){
    this.afAuth.auth.signOut()
        .then(() => {
          obj.goToLogin();
        })
        .catch(error => {

        });
  }

  resetPassword(obj,email){
    this.afAuth.auth.sendPasswordResetEmail(email)
      .then(() => {
        obj.resetSuccess();
      })
      .catch(error => {
        obj.resetFail(error);
      });
  }

  saveImage(obj, image){
    const file = image;
    const filePath = '/images/'+ Math.floor((Math.random() * 100000) + 1) +'.jpg';
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    
    
    task.then(()=>{
      fileRef.getDownloadURL().subscribe(params => {
        obj.setCanvasImage(params);
      });
    })
    .catch(error => {
      console.log("fail to upload" + error);
      obj.imageUploadError(error);
    } )
  }
  
  getDate(){
    var d = new Date();
    var year = d.getUTCFullYear();
    var month = d.getUTCMonth() + 1;
    var day = d.getUTCDate() + 1;
    var hour = d.getUTCHours() + 1;
    var minute = d.getUTCMinutes() + 1;
    return year + "-" + month + "-" + day + " " + hour + ":" + minute
  }

}
