import { Component, OnInit, ElementRef, ViewChild, Input, NgZone, HostListener, ɵConsole} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { CanvasService } from '../services/canvas.service';
import { AngularFireAction } from '@angular/fire/database';
import { Observable } from 'rxjs';
import * as RecordRTC from 'recordrtc';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, switchMap } from 'rxjs/operators';
import { e } from '@angular/core/src/render3';
import { timingSafeEqual } from 'crypto';
import { FLAGS } from '@angular/core/src/render3/interfaces/view';
import { DataSnapshot } from '@angular/fire/database/interfaces';
declare var $: any;


const SERVERS: any = {
  iceServers: [
    { urls: 'stun:stun.services.mozilla.com' },
    { urls: 'stun:stun.l.google.com:19302' }
  ]
};

const DEFAULT_CONSTRAINTS = {
  optional: []
};

declare let RTCPeerConnection: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ AuthenticationService ]
})

export class HomeComponent implements OnInit {

  authState: any = null;
  userAccessType = '';
  @ViewChild('whiteboardCanvas') public whiteboardCanvas: ElementRef;
  @ViewChild('powerpointCanvas') public powerpointCanvas: ElementRef;
  @ViewChild('textInputDiv') public textInputDiv: ElementRef;
  @ViewChild('idInputText') public textInput: ElementRef;
  @ViewChild('img') public img: ElementRef;
  @ViewChild('canvasImage') public canvasImage: ElementRef;
  @ViewChild('loading') public loading: ElementRef;
  @ViewChild('testVideo') public testVideo: ElementRef;
  @ViewChild('homeContainer') public homeContainer: ElementRef;
  @ViewChild('videoProgressBar') public videoProgressBar: ElementRef;
  @ViewChild('modalTitle') public modalTitle: ElementRef;
  @ViewChild('startRecordBtn') public startRecordBtn: ElementRef;
  @ViewChild('stopRecordBtn') public stopRecordBtn: ElementRef;
  @ViewChild('uploadProgressBtn') public uploadProgressBtn: ElementRef;
  @ViewChild('canvasDivTest') public canvasDiv: ElementRef;
  @ViewChild('chooseFile') public chooseFile: ElementRef;
  @ViewChild('muteBtnDiv') public muteBtnDiv: ElementRef;
  @ViewChild('unmuteBtnDiv') public unmuteBtnDiv: ElementRef;
  @ViewChild('micDisableBtnDiv') public micDisableBtnDiv: ElementRef;
  @ViewChild('micEnableBtnDiv') public micEnableBtnDiv: ElementRef;
  @ViewChild('voiceRequestBtnDiv') public voiceRequestBtnDiv: ElementRef;
  @ViewChild('chatRequestModalCloseBtn') public chatRequestModalCloseBtn: ElementRef;
  @ViewChild('leftToolbarInputColor') public leftToolbarInputColor: ElementRef;
  @ViewChild('rightToolbarInputColor') public rightToolbarInputColor: ElementRef;
  @ViewChild('shapePopOverBtn') public shapePopOverBtn: ElementRef;
  @ViewChild('rightToolbar2') public rightToolbar2: ElementRef;
  @ViewChild('rightToolbar1') public rightToolbar1: ElementRef;
  @ViewChild('leftToolbar1') public leftToolbar1: ElementRef;
  context: any;
  canvasEl: HTMLCanvasElement;
  imageEl: HTMLImageElement;
  startPoint: any;
  snapshot: any;
  isDragging = false;
  isImageDragging = false;
  selectedShape = 'freehand';
  eraserOn = false;
  finger: any = 1;
  snapshotArrayPage: any[] = new Array();
  remoteCoordinatesArray: any[] = new Array();
  remoteTempCoordinatesArray: any[] = new Array();
  redrawTempCoordinatesArray: any[] = new Array();
  stepPage: any = -1;
  rectangleHeight: any = 0;
  rectangleWidth: any = 0;
  isFill = false;
  canvasColor = '#404040';
  lineWidth: any = 1;
  inputText = '';
  imageFile: any = null;
  canvasImgX: any = 0;
  canvasImgY: any = 0;
  canvasImgWidth: any = 0;
  canvasImgHeight: any = 0;
  fileError = '';
  imageType = '';
  firstLoaded = true;
  sessionHeaderKey = '';
  userUID = '';
  pageKey = '';
  pageCount: any = 1;
  shareCode = '';
  items$: Observable<AngularFireAction<DataSnapshot>[]> ;
  data: Observable <any>;
  canvasComponentData: any;
  isImageLoading = false;
  isRemoteImageLoading = false;
  isManualDrawing = false;
  isCanvasAdmin = false;
  isCanvasViewer = false;
  isCanvasEditor = false;
  textMsg = '';
  messagesArrayObservable: Observable<any[]>;
  messagesArray: any[] = new Array();
  requestsArrayObservable: Observable<any[]>;
  requestsArray: any[] = new Array();
  chatUsersArrayObservable: Observable<any[]>;
  chatUsersArray: any[] = new Array();
  isSessionRequestSending = false;
  isRequestSent = false;
  isVoiceRequestSent = false;
  isVoiceRequestSending = false;
  isLoading = false;
  RTCpeersArray: any[] = new Array();
  pcObj: any;
  isChatting = false;
  isMute = false;
  remoteAudioEl: any;
  imageUrl: any;
  remoteCommand = '';
  dataURL = '';
  isWhiteBoard = false;
  pageKeysArray: any[] = new Array();
  points: any[] = new Array();
  deletePageKey: any;
  scale: any = 1;
  recorder: any;
  recordCanvas: any;
  canvasCapture: any;
  isStreaming = true;
  isRecordingStart = false;
  isRecordingStop = false;
  isVideoUploading = false;
  tempFillStyle: any;
  thumbnailCanvasUrl: any;
  thumbnailUrl: any;
  videoUrl: any;
  firstLoad = true;
  pos1: any = 0;
  pos2: any = 0;
  pos3: any = 0;
  pos4: any = 0;
  pi2: any = Math.PI * 2;
  resizerRadius: any = 10;
  rr: any;
  draggingResizer: any;
  imageX: any = 50;
  imageY: any = 50;
  imageWidth: any;
  imageHeight: any;
  imageRight: any;
  imageBottom: any;
  selectedImage: any;
  isImageSelected = false;
  prevSelectedShape: any = 'freehand';
  positionX: any;
  positionY: any;
  startPointX: any;
  startPointY: any;
  circleRadius: any;
  isShapeSelected = false;
  isShapeDragging = false;
  isShapeDrawn = false;
  isUserMicEnabled = false;
  globalStream1: any;
  globalStream2: any;
  streamArray: any[] = new Array();
  isColorDialogOpen = false;
  isShapePopover = false;
  isLineWidthPopover = false;
  isPagesBarOpen = false;
  eraserWidth: any = 5;
  powerPointUrl: any = '';
  eraserPoints: any[] = new Array();
  tempStrokeStyle: any;
  tempLineWidth: any;
  canvasHeight = 450;
  canvasWidth = 810;
  scaleMargin = 0.05;

  constructor(private router: Router,
              private service: AuthenticationService,
              private canvasService: CanvasService,
              private zone: NgZone,
              private storage: AngularFireStorage) {
   }

  ngOnInit() {
    navigator.mediaDevices.getUserMedia({ audio: true });
    this.initialiseJquery();

    // Getting all the required information about the session
    this.canvasComponentData = this.canvasService.getCanvasComponentData();
    if (!this.canvasComponentData.fromDashboard) {
      // console.log("hello 1");
      if (window.localStorage.getItem('userUID') &&
          window.localStorage.getItem('sessionHeaderKey') &&
          window.localStorage.getItem('shareCode') &&
          window.localStorage.getItem('sessionType')) {
        this.userUID = window.localStorage.getItem('userUID');
        this.sessionHeaderKey = window.localStorage.getItem('sessionHeaderKey');
        this.shareCode = window.localStorage.getItem('shareCode');
        if (window.localStorage.getItem('sessionType') === 'whiteboard') {
          this.isWhiteBoard = true;
        } else if (window.localStorage.getItem('sessionType') === 'powerpoint') {
          this.isWhiteBoard = false;
        }
        this.initialiseCanvas();
        this.addListeners();
        this.startSession();
      } else {
        this.goToDashboard();
      }
    } else {
      // console.log("hello2" + this.canvasComponentData.sessionHeaderKey);
      this.userUID = this.canvasComponentData.userUID;
      if (this.canvasComponentData.sessionType === 'whiteboard') {
        // console.log("whiteboard" + this.canvasComponentData.sessionType);
        this.isWhiteBoard = true;
      } else if (this.canvasComponentData.sessionType === 'powerpoint') {
        this.isWhiteBoard = false;
      }
      this.initialiseCanvas();
      this.addListeners();
      window.localStorage.setItem('sessionType', this.canvasComponentData.sessionType);
      window.localStorage.setItem('userUID', this.userUID);
      if (this.canvasComponentData.sessionHeaderKey !== '') {
        this.sessionHeaderKey = this.canvasComponentData.sessionHeaderKey;
        window.localStorage.setItem('sessionHeaderKey', this.sessionHeaderKey);
        this.shareCode = this.canvasComponentData.shareCode;
        window.localStorage.setItem('shareCode', this.shareCode);
        this.startSession();
      } else {
        this.isCanvasAdmin = true;
        this.isCanvasEditor = true;
        this.generateShareCode();
        window.localStorage.setItem('shareCode', this.shareCode);
        // this.canvasService.intiateSession(this, this.userUID, this.shareCode, "grid", this.canvasColor);
      }
    }
    this.rr = this.resizerRadius * this.resizerRadius;

  }

  initialiseJquery() {
    $(document).ready(function() {
      $('[data-toggle="colorPopover"]').popover({
        html: true,
        content: function() {
          return $('#colorPopoverContent').html();
        }
      });

      $('[data-toggle="tooltip"]').tooltip();

      $('#pagesSideBar').hide();

      $('#chatSideBar').hide();

      $('#rigthRequestBar').hide();

      $('#voiceChatUsers').hide();

      $('#floatBtn').click(function() {
        if ($('#pagesSideBar').is(':hidden')) {
          $('#pagesSideBar').show(1000);
        } else {
          $('#pagesSideBar').hide(1000);
        }
      });

      $('#floatBtnMsg').click(function() {
        if ($('#chatSideBar').is(':hidden')) {
          $('#chatSideBar').show(500);
        } else {
          $('#chatSideBar').hide(500);
        }
      });

      $('#closeBtn').click(function() {
        $('#pagesSideBar').hide(500);
      });

      $('#closeBtnMsg').click(function() {
        $('#chatSideBar').hide(500);
      });

      $('#floatBtnRequest').click(function() {
        if ($('#rigthRequestBar').is(':hidden')) {
          $('#rigthRequestBar').show(500);
        } else {
          $('#rigthRequestBar').hide(500);
        }
      });

      $('#closeBtnRequest').click(function() {
        $('#rigthRequestBar').hide(500);
      });

      $('#chatUsersBtn').click(function() {
        if ($('#voiceChatUsers').is(':hidden')) {
          $('#voiceChatUsers').show(500);
        } else {
          $('#voiceChatUsers').hide(500);
        }
      });

      $('#closeBtnVoiceChatUsers').click(function() {
        $('#voiceChatUsers').hide(500);
      });

    });
  }

  startSession() {
    // This function is called to get all the ids of the pages and start monitor of the first page
    this.canvasService.setResumeSession(this, this.sessionHeaderKey, this.isWhiteBoard);

    this.monitorPageData(this.sessionHeaderKey);
    this.getCanvasMessages();
    this.canvasService.removeRTCUser(this, this.userUID, this.sessionHeaderKey);
    // this.canvasService.monitorRtcDetails(this, this.sessionHeaderKey);
    console.log('userUID' + this.userUID);
  }

  public initialiseCanvas() {
    // Fixed aspect ratio 16:9
    // Fixed canvas size 800:450
    if(this.isWhiteBoard){
      this.recordCanvas = document.getElementById("whiteBoardCanvas");
      this.canvasEl = this.whiteboardCanvas.nativeElement;
      this.context = this.canvasEl.getContext("2d", { alpha: false });
      var availableClientHeight = this.canvasDiv.nativeElement.offsetHeight;
      var availableClientWidth = this.canvasDiv.nativeElement.offsetWidth;
      //debugger;
      if(availableClientHeight * 1.8 <= availableClientWidth){
        this.scale = availableClientHeight / this.canvasHeight;
        //this.canvasEl.style.height = availableClientHeight - 5 + "px";
        //this.canvasEl.style.width = availableClientHeight * 1.8 + "px";
      }else{
        this.scale = availableClientWidth / this.canvasWidth;
        //this.canvasEl.style.height = availableClientWidth / 1.8 + "px";
        //this.canvasEl.style.width = availableClientWidth + "px";
      }

      console.log("Scale: " + this.scale);
      if(this.scale > 1) {
        this.scale = this.scale - 0.1 - this.scaleMargin;
        this.zoomIn();
      } else {
        this.scale = this.scale + 0.1 - this.scaleMargin;
        this.zoomOut();
      }

      this.canvasEl.width = this.canvasWidth;
      this.canvasEl.height = this.canvasHeight;
      this.scaleToolbars(availableClientHeight);
    } else {
      this.recordCanvas = document.getElementById('powerpointCanvas');
      this.canvasEl = this.powerpointCanvas.nativeElement;
      this.context = this.canvasEl.getContext('2d', { alpha: false });
      this.canvasEl.width = this.canvasWidth;
      this.canvasEl.height = this.canvasHeight;
    }

    // This code for pasting a white background for preventing transparent background during canvas recording
    const tempFillStyle = this.context.fillStyle;
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    this.context.fillStyle = tempFillStyle;
    this.context.lineCap = 'round';
    this.context.lineJoin = 'round';

    // Push a empty canvas in snapshot array as first element for undo redo process
    if (this.isWhiteBoard) {
      this.stepPage++;
      this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
    }

    this.context.lineCap = 'round';
    this.context.lineJoin = 'round';
  }

  resizeCanvas() {
    var availableClientHeight = this.canvasDiv.nativeElement.offsetHeight;
    var availableClientWidth = this.canvasDiv.nativeElement.offsetWidth;
    
    if (this.isWhiteBoard) {
      if(availableClientHeight * 1.8 <= availableClientWidth){
        this.scale = availableClientHeight / this.canvasHeight;
        //this.canvasEl.style.height = availableClientHeight - 5 + "px";
        //this.canvasEl.style.width = availableClientHeight * 1.8 + "px";
      }else{
        this.scale = availableClientWidth / this.canvasWidth;
        //this.canvasEl.style.height = availableClientWidth / 1.8 + "px";
        //this.canvasEl.style.width = availableClientWidth + "px";
      }
      // const availableClientHeight = this.canvasDiv.nativeElement.offsetHeight;
      // if (availableClientHeight * 1.8 <= this.canvasDiv.nativeElement.offsetWidth) {
      //   this.canvasEl.style.height = availableClientHeight + 'px';
      //   this.canvasEl.style.width = availableClientHeight * 1.8 + 'px';
      // } else {
      //   this.canvasEl.style.height = this.canvasDiv.nativeElement.offsetWidth / 1.8 + 'px';
      //   this.canvasEl.style.width = this.canvasDiv.nativeElement.offsetWidth + 'px';
      // }

      // this.canvasEl.width = innerWidth;
      // this.canvasEl.height = innerHeight;
    
      if(this.scale > 1) {
        this.scale = this.scale - 0.1 - this.scaleMargin;
        this.zoomIn();
      } else {
        this.scale = this.scale + 0.1 - this.scaleMargin;
        this.zoomOut();
      }

      this.scaleToolbars(availableClientHeight);
    } else {
      this.canvasEl.width = this.canvasWidth;
      this.canvasEl.height = this.canvasHeight;
    }
    this.setCanvasColor(this.canvasColor);
    this.setLineWidth();

  }

  scaleToolbars(height){
    var scaleH = 1;
    var scaleString = "";
    var toolbarOuterHeight = 450;

    if(height <= toolbarOuterHeight) {
      scaleH = (height / toolbarOuterHeight ) - 0.1;
      scaleString = 'scale('
        + scaleH
        + ','
        + scaleH
        + ')';
    } else {
      scaleH = 1;
      scaleString = 'scale('
        + scaleH
        + ','
        + scaleH
        + ')';
    }

    this.leftToolbar1.nativeElement.style.transform = scaleString;
    this.rightToolbar1.nativeElement.style.transform = scaleString;
    this.rightToolbar2.nativeElement.style.transform = scaleString;
  }

  zoomIn(){
    if(this.scale < 10){
      this.scale = this.scale + 0.1;
      this.canvasEl.style.transform = 'scale('
        + this.scale
        + ','
        + this.scale
        + ')';

      console.log("scale " + this.scale);
    } else {
      this.scale = 10 - 0.1;
      this.zoomIn();
    }
  }

  zoomOut(){
    if(this.scale > 0.2){
      this.scale = this.scale - 0.1;
      this.canvasEl.style.transform = 'scale('
        + this.scale
        + ','
        + this.scale
        + ')';

      // console.log("scale " + this.scale);
    } else {
      this.scale = 0.2 + 0.1;
      this.zoomOut();
    }
  }

  // Listent to all the pointer and touch events
  addListeners() {
    this.canvasEl.addEventListener('mousedown', this.dragStart.bind(this));
    this.canvasEl.addEventListener('touchstart', this.dragStartTouch.bind(this));
    this.canvasEl.addEventListener('mousemove', this.drag.bind(this));
    this.canvasEl.addEventListener('touchmove', this.dragTouch.bind(this));
    this.canvasEl.addEventListener('mouseup', this.dragStop.bind(this));
    this.canvasEl.addEventListener('mouseleave', this.dragStop.bind(this));
    this.canvasEl.addEventListener('touchend', this.dragStopTouch.bind(this));
  }

  public ngAfterViewInit() {

    // this.canvasEl.addEventListener("mousedown",this.dragStart,false);
  }

  setShape(shape, isFill) {
    if (this.isCanvasEditor) {
      if (this.isImageSelected) {
        this.restoreSnapshot();
        this.isImageSelected = false;
      } else if (this.isShapeSelected) {
        this.restoreSnapshot();
        switch (this.selectedShape) {
          case 'line':
          this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);          break;
          case 'rectangle':
          this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                              },
                                              {x : this.rectangleWidth, y : this.rectangleHeight},
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);          break;
          case 'circle':
            this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);
              break;
          case 'ellipse':
            this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
              x: this.startPointX,
              y: this.startPointY
              },
              {
                x: this.positionX,
                y: this.positionY
              },
              this.selectedShape,
              this.isFill,
              this.lineWidth,
              this.canvasColor,
              this.sessionHeaderKey,
              this.pageKey,
              this.userUID);
            break;
        }
        this.pushSnapshot(false);
        this.sendPageDataToService();
        this.isShapeSelected = false;
      }

      if (shape === 'imagearea') {
        if (this.selectedShape !== 'imagearea') {
          this.prevSelectedShape = this.selectedShape;
        }
        this.chooseFile.nativeElement.click();
      }
      this.selectedShape = shape;
      this.isFill = isFill;
    } else {
      this.showSnackbar('You dont have permission to draw');
    }
  }

  setCanvasColor(color) {
    console.log('set color');
    this.context.strokeStyle = color;
    this.context.fillStyle = color;
    this.canvasColor = color;
    this.textInput.nativeElement.style.color = color;
  }

  setLineWidth() {
    this.context.lineWidth = this.lineWidth;
    this.context.lineCap = 'round';
    this.context.lineJoin = 'round';
  }

  getCanvasCoordinates(event, isTouch) {
    // console.log("canvas width " + this.canvasEl.width);
    // console.log("canvas client width " + this.canvasEl.clientWidth);
    // console.log("offsetX " + event.offsetX);
    // var x = event.clientX - this.canvasEl.getBoundingClientRect().left;
    // var y = event.clientY - this.canvasEl.getBoundingClientRect().top;
    let x = event.offsetX * this.canvasEl.width / this.canvasEl.clientWidth | 0;
    let y = event.offsetY * this.canvasEl.height / this.canvasEl.clientHeight | 0;
    if (isTouch) {
      x = x / this.scale;
      y = y / this.scale;
    }
    return {
      x: x,
      y: y
    };
  }

  takeSnapshot() {
    this.snapshot = this.context.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
    this.dataURL = this.canvasEl.toDataURL('image/png', 0.1);
  }

  restoreSnapshot() {
    this.context.putImageData(this.snapshot, 0, 0);

  }

  pushOriginPPTsnapshot(pageData) {
    this.stepPage++;
    this.snapshotArrayPage.push(pageData);
  }

  pushSnapshot(isRemote: boolean) {
    this.takeSnapshot();
    this.stepPage++;
    if (this.stepPage < this.snapshotArrayPage.length) { this.snapshotArrayPage.length = this.stepPage; }
    this.snapshotArrayPage.push(this.dataURL);

    // Send data to
    if (!isRemote  && this.selectedShape !== 'imagearea' && this.selectedShape !== 'textarea') {
      this.canvasService.saveCanvasData({x: 0, y: 0},
        {x: 0, y: 0},
        'pushSnapshot',
        this.isFill,
        this.lineWidth,
        this.canvasColor,
        this.sessionHeaderKey,
        this.pageKey,
        this.userUID );
    }
  }

  pushNewPageSnapshot() {
    this.snapshotArrayPage = [];
    this.stepPage = -1;
    this.stepPage++;
    this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
  }

  undoCanvas(isRemote: boolean) {
    console.log("canvasd editor: " + this.isCanvasEditor);
    if (this.isCanvasEditor) {
      if (this.isImageSelected) {
        this.restoreSnapshot();
        this.isImageSelected = false;
      } else if (this.isShapeSelected) {
        this.restoreSnapshot();
        switch (this.selectedShape) {
          case 'line':
          this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);          break;
          case 'rectangle':
          this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                              },
                                              {x : this.rectangleWidth, y : this.rectangleHeight},
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);          break;
          case 'circle':
            this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);
              break;
          case 'ellipse':
            this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
              x: this.startPointX,
              y: this.startPointY
              },
              {
                x: this.positionX,
                y: this.positionY
              },
              this.selectedShape,
              this.isFill,
              this.lineWidth,
              this.canvasColor,
              this.sessionHeaderKey,
              this.pageKey,
              this.userUID);
            break;
        }
        this.pushSnapshot(false);
        this.sendPageDataToService();
        this.isShapeSelected = false;
      }

      console.log("Step Page: " + this.stepPage);

      if (this.stepPage > 0) {
        console.log("Hey 1");
        this.stepPage--;
        if (!isRemote) {
          console.log("Hey 2");
          const img = new Image();
          img.onload = function() {
            this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
            if (this.isWhiteBoard) {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            } else {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            }
            this.sendPageDataToService();
          }.bind(this);
          img.src = this.snapshotArrayPage[this.stepPage];
          img.crossOrigin = 'Anonymous';
          this.isManualDrawing = true;
          this.canvasService.saveCanvasData({x: 0, y: 0},
            {x: 0, y: 0},
            'undo',
            this.isFill,
            this.lineWidth,
            this.canvasColor,
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
        } else {
          console.log("Hey 3");
          this.isRemoteImageLoading = true;
          this.isLoading = true;
          const img = new Image();
          img.onload = function() {
            this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
            if (this.isWhiteBoard) {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            } else {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            }
            this.isRemoteImageLoading = false;
            if (this.remoteTempCoordinatesArray.length == 0) {
              this.isLoading = false;
            } else {
              for (let i = 0; i < this.remoteTempCoordinatesArray.length; i++) {
                // console.log(" undo in loop");
                this.isLoading = true;
                // tslint:disable-next-line: max-line-length
                if (this.remoteTempCoordinatesArray[i].type === 'undo' || this.remoteTempCoordinatesArray[i].type === 'redo' || this.remoteTempCoordinatesArray[i].type === 'imagearea') {
                  this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
                    this.remoteTempCoordinatesArray[i].startPointY,
                    this.remoteTempCoordinatesArray[i].currentPointX,
                    this.remoteTempCoordinatesArray[i].currentPointY,
                    this.remoteTempCoordinatesArray[i].type,
                    this.remoteTempCoordinatesArray[i].isFill,
                    this.remoteTempCoordinatesArray[i].lineWidth,
                    this.remoteTempCoordinatesArray[i].canvasColor,
                    this.remoteTempCoordinatesArray[i].userUID,
                    this.remoteTempCoordinatesArray[i].pageKey);
                    if (i + 1 == this.remoteTempCoordinatesArray.length) {
                      this.isLoading = false;
                    }
                    this.remoteTempCoordinatesArray.splice(0, i + 1);
                    break;
                } else {
                  this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
                    this.remoteTempCoordinatesArray[i].startPointY,
                    this.remoteTempCoordinatesArray[i].currentPointX,
                    this.remoteTempCoordinatesArray[i].currentPointY,
                    this.remoteTempCoordinatesArray[i].type,
                    this.remoteTempCoordinatesArray[i].isFill,
                    this.remoteTempCoordinatesArray[i].lineWidth,
                    this.remoteTempCoordinatesArray[i].canvasColor,
                    this.remoteTempCoordinatesArray[i].userUID,
                    this.remoteTempCoordinatesArray[i].pageKey);
                }
                if (this.remoteTempCoordinatesArray.length == i + 1) {
                  this.remoteTempCoordinatesArray = [];
                  this.isLoading = false;
                }
              }
            }
          }.bind(this);
          img.src = this.snapshotArrayPage[this.stepPage];
          img.crossOrigin = 'Anonymous';
        }
      }
    } else {
      console.log("Is Canvas: " + this.isCanvasEditor);
      if (!isRemote) {
        this.showSnackbar('You dont have permission to undo');
      }
    }
  }

  redoCanvas(isRemote: boolean) {
    if (this.isCanvasEditor) {
      if (this.isImageSelected) {
        this.restoreSnapshot();
        this.isImageSelected = false;
      } else if (this.isShapeSelected) {
        this.restoreSnapshot();
        switch (this.selectedShape) {
          case 'line':
          this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);          break;
          case 'rectangle':
          this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                              },
                                              {x : this.rectangleWidth, y : this.rectangleHeight},
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);          break;
          case 'circle':
            this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
                                              x: this.startPointX,
                                              y: this.startPointY
                                          },
                                          {
                                            x: this.positionX,
                                            y: this.positionY
                                          },
                                          this.selectedShape,
                                          this.isFill,
                                          this.lineWidth,
                                          this.canvasColor,
                                          this.sessionHeaderKey,
                                          this.pageKey,
                                          this.userUID);
              break;
          case 'ellipse':
            this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
            this.canvasService.saveCanvasData({
              x: this.startPointX,
              y: this.startPointY
              },
              {
                x: this.positionX,
                y: this.positionY
              },
              this.selectedShape,
              this.isFill,
              this.lineWidth,
              this.canvasColor,
              this.sessionHeaderKey,
              this.pageKey,
              this.userUID);
            break;
        }
        this.pushSnapshot(false);
        this.sendPageDataToService();
        this.isShapeSelected = false;
      }


      if (this.stepPage < this.snapshotArrayPage.length - 1) {
        this.stepPage++;
        if (!isRemote) {
          const img = new Image();
          img.onload = function() {
            this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
            if (this.isWhiteBoard) {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            } else {
              this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            }
            this.sendPageDataToService();
          }.bind(this);
          img.src = this.snapshotArrayPage[this.stepPage];
          img.crossOrigin = 'Anonymous';
          this.isManualDrawing = true;
          this.canvasService.saveCanvasData({x: 0, y: 0},
            {x: 0, y: 0},
            'redo',
            this.isFill,
            this.lineWidth,
            this.canvasColor,
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
        } else {
            this.isRemoteImageLoading = true;
            this.isLoading = true;
            const img = new Image();
            img.onload = function() {
              this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
              if (this.isWhiteBoard) {
                this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
              } else {
                this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
              }
              this.isRemoteImageLoading = false;
              if (this.remoteTempCoordinatesArray.length == 0) {
                this.isLoading = false;
              } else {
                for (let i = 0; i < this.remoteTempCoordinatesArray.length; i++) {
                  this.isLoading = true;
                  if (this.remoteTempCoordinatesArray[i].type === 'undo' || this.remoteTempCoordinatesArray[i].type === 'redo' || this.remoteTempCoordinatesArray[i].type === 'imagearea') {
                    // console.log("for loop redo");
                    this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
                      this.remoteTempCoordinatesArray[i].startPointY,
                      this.remoteTempCoordinatesArray[i].currentPointX,
                      this.remoteTempCoordinatesArray[i].currentPointY,
                      this.remoteTempCoordinatesArray[i].type,
                      this.remoteTempCoordinatesArray[i].isFill,
                      this.remoteTempCoordinatesArray[i].lineWidth,
                      this.remoteTempCoordinatesArray[i].canvasColor,
                      this.remoteTempCoordinatesArray[i].userUID,
                      this.remoteTempCoordinatesArray[i].pageKey);
                      if (i + 1 == this.remoteTempCoordinatesArray.length) {
                        this.isLoading = false;
                      }
                      this.remoteTempCoordinatesArray.splice(0, i + 1);
                      break;
                  } else {
                    this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
                      this.remoteTempCoordinatesArray[i].startPointY,
                      this.remoteTempCoordinatesArray[i].currentPointX,
                      this.remoteTempCoordinatesArray[i].currentPointY,
                      this.remoteTempCoordinatesArray[i].type,
                      this.remoteTempCoordinatesArray[i].isFill,
                      this.remoteTempCoordinatesArray[i].lineWidth,
                      this.remoteTempCoordinatesArray[i].canvasColor,
                      this.remoteTempCoordinatesArray[i].userUID,
                      this.remoteTempCoordinatesArray[i].pageKey);
                  }

                  if (this.remoteTempCoordinatesArray.length == i + 1) {
                    this.remoteTempCoordinatesArray = [];
                    this.isLoading = false;
                  }
                }
              }
            }.bind(this);
            img.src = this.snapshotArrayPage[this.stepPage];
            img.crossOrigin = 'Anonymous';
        }
      }
    } else {
      if (!isRemote) {
        this.showSnackbar('You dont have permission to redo');
      }
    }

  }

  drawLine(startPointX, startPointY, positionX, positionY, withAnchors) {
    this.context.beginPath();
    this.context.moveTo(startPointX, startPointY);
    this.context.lineTo(positionX, positionY);
    this.context.stroke();
    if (withAnchors) {
      this.drawDragAnchor(positionX, positionY);
      this.drawCrossAnchor(startPointX, startPointY);
    }
  }

  drawCircle(startPointX, startPointY, positionX, positionY, withAnchors) {
    this.circleRadius = Math.sqrt(Math.pow((startPointX - positionX), 2) + Math.pow((startPointY - positionY), 2));
    this.context.beginPath();
    this.context.arc(startPointX, startPointY, this.circleRadius, 0, 2 * Math.PI, false);
    if (this.isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
    if (withAnchors) {
      this.drawDragAnchor(positionX, positionY);
      this.drawCrossAnchor(startPointX, startPointY);
    }
  }

  drawEllipse(startPointX, startPointY, positionX, positionY, withBorder) {
    if (withBorder) {
      const tempLineWidth = this.context.lineWidth;
      this.context.lineWidth = 1;
      this.context.setLineDash([6]);
      const borderWidth = positionX - startPointX;
      const borderHeight = positionY - startPointY;
      this.context.beginPath();
      this.context.rect(startPointX, startPointY, borderWidth, borderHeight);
      this.context.stroke();
      this.context.setLineDash([]);
      this.drawDragAnchor(positionX, positionY);
      this.drawCrossAnchor(startPointX, startPointY);
      this.context.lineWidth = tempLineWidth;
    }
    this.context.save();
    this.context.beginPath();
    //Dynamic scaling
    const scalex = 1 * ((positionX - startPointX) / 2);
    const scaley = 1 * ((positionY - startPointY) / 2);
    this.context.scale(scalex, scaley);
    //Create ellipse
    const centerx = (startPointX / scalex) + 1;
    const centery = (startPointY / scaley) + 1;
    this.context.arc(centerx, centery, 1, 0, 2 * Math.PI);
    //Restore and draw

    // this.context.beginPath();
    // this.context.ellipse(this.startPoint.x, this.startPoint.y, Math.abs(this.startPoint.y - position.y), Math.abs(this.startPoint.x - position.x), Math.PI / 2, 0, 2 * Math.PI);

    this.context.restore();
    if (this.isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
  }

  midPointBtw(p1, p2) {
    return {
      x: p1.x + (p2.x - p1.x) / 2,
      y: p1.y + (p2.y - p1.y) / 2
    };
  }


  drawFreehand(position) {

    this.points.push({ x: position.x, y: position.y });
    this.restoreSnapshot();
    let p1 = this.points[0];
    let p2 = this.points[1];
    this.context.beginPath();
    this.context.moveTo(this.startPoint.x, this.startPoint.y);

    for (let i = 1, len = this.points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      const midPoint = this.midPointBtw(p1, p2);
      this.context.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
      p1 = this.points[i];
      p2 = this.points[i + 1];
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    this.context.lineTo(p1.x, p1.y);
    this.context.stroke();
    // this.context.lineTo(position.x, position.y);
    // this.context.stroke();
    // this.startPoint.x = position.x;
    // this.startPoint.y = position.y;
  }

  drawRectangle(startPointX, startPointY, positionX, positionY, withAnchors) {
    this.rectangleWidth = positionX - startPointX;
    this.rectangleHeight = positionY - startPointY;
    this.context.beginPath();
    this.context.rect(startPointX, startPointY, this.rectangleWidth, this.rectangleHeight);
    if (this.isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
    if (withAnchors) {
      this.drawDragAnchor(positionX, positionY);
      this.drawCrossAnchor(startPointX, startPointY);
    }
  }

  fileSelected(event) {
    console.log('onchange ' + event);
    this.imageType = event.target.files[0].type;
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.takeSnapshot();
      const reader = new FileReader();
      reader.onload = function() {
        this.isImageSelected = true;
        this.initialiseSelectedImage(reader.result);
      }.bind(this);
      this.imageFile = event.target.files[0];
      // console.log("files " + event.target.files[0]);
      reader.readAsDataURL(event.target.files[0]);
      this.fileError = '';
      event.target.value = '';
    } else {
      this.fileError = 'Invalid file';
    }
  }

  eraseCanvas(position) {
    this.context.fillStyle = 'white';
    this.context.beginPath();
    this.context.arc(position.x, position.y, this.eraserWidth, 0, 2 * Math.PI, false);
    this.context.fill();

    // Using clear canvas method
    // this.context.clearRect(position.x, position.y, this.eraserWidth, this.eraserWidth);


    // Erase using rectangle
    // this.context.fillStyle = "white";
    // this.context.fillRect(position.x, position.y, this.eraserWidth, this.eraserWidth);
  }

  createTextarea(event) {
    this.textInputDiv.nativeElement.style.top = event.clientY - 30 + 'px';
    this.textInputDiv.nativeElement.style.left = event.clientX - 7 + 'px';
    this.textInputDiv.nativeElement.style.display = '';
  }

  vanishTextInput() {
    this.textInputDiv.nativeElement.style.display = 'none';
    this.inputText = '';
  }

  sendImageToServer() {
    if (this.imageFile != null) {
      if (this.imageType === 'image/jpeg' || this.imageType === 'image/png') {
        this.isLoading = true;
        this.service.saveImage(this, this.imageFile);
        this.fileError = '';
      } else {
        this.fileError = 'Invalid file';
      }
    } else {
      this.fileError = 'No file';
    }
  }

  setCanvasText() {
    this.isManualDrawing = true;

    this.canvasService.saveCanvasData(this.startPoint,
      {x: this.inputText, y: 0},
      this.selectedShape,
      this.isFill,
      this.lineWidth,
      this.canvasColor,
      this.sessionHeaderKey,
      this.pageKey,
      this.userUID);

    this.textInputDiv.nativeElement.style.display = 'none';
    this.context.font = '20px sans-serif';
    this.context.fillText(this.inputText, this.startPoint.x, this.startPoint.y);
    this.pushSnapshot(false);
    this.inputText = '';
    this.sendPageDataToService();
  }

  setCanvasImage(url) {
    this.isManualDrawing = true;
    const img = new Image();
    img.onload = function() {
      this.selectedShape = 'imagearea';
      this.canvasService.saveCanvasData({x: this.imageX, y: this.imageY},
        {x: this.imageWidth, y: this.imageHeight},
        this.selectedShape,
        url,
        this.lineWidth,
        this.canvasColor,
        this.sessionHeaderKey,
        this.pageKey,
        this.userUID );
      this.restoreSnapshot();
      this.isImageSelected = false;
      this.context.drawImage(img, this.imageX, this.imageY, this.imageWidth, this.imageHeight);
      this.isLoading = false;
      this.pushSnapshot(false);
      this.sendPageDataToService();
      this.imageX = 50;
      this.imageY = 50;
      this.selectedShape = this.prevSelectedShape;
    }.bind(this);
    img.src = url;
    this.imageUrl = url;
    img.crossOrigin = 'Anonymous';
  }

  imageUploadError(error) {
    this.loading.nativeElement.style.display = 'none';
    this.isLoading = false;
  }

  dragStart(event) {
    if (this.isCanvasEditor) {
      this.isDragging = true;
      this.isManualDrawing = true;
      this.startPoint = this.getCanvasCoordinates(event, false);
      switch (this.selectedShape) {
        case 'freehand':
          this.takeSnapshot();
          this.points.push({ x: this.startPoint.x, y: this.startPoint.y });
          this.canvasService.saveCanvasData(this.startPoint,
            this.startPoint,
            this.selectedShape,
            this.isFill,
            this.lineWidth,
            this.canvasColor,
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          break;
        case 'circle':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.draggingResizer == 0 || this.checkPointOnTheCircle(this.startPoint.x, this.startPoint.y) == 1) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                                },
                                                {
                                                  x: this.positionX,
                                                  y: this.positionY
                                                },
                                                this.selectedShape,
                                                this.isFill,
                                                this.lineWidth,
                                                this.canvasColor,
                                                this.sessionHeaderKey,
                                                this.pageKey,
                                                this.userUID);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
            }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'line':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.checkPointOnTheLine(this.startPoint.x, this.startPoint.y) < 5 || this.draggingResizer == 0) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                              },
                                              {
                                                x: this.positionX,
                                                y: this.positionY
                                              },
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
            }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'rectangle':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.draggingResizer == 0 || this.checkPointOnTheRectangle(this.startPoint.x, this.startPoint.y) == 1) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.canvasService.saveCanvasData({
                                                      x: this.startPointX,
                                                      y: this.startPointY
                                                  },
                                                  {x : this.rectangleWidth, y : this.rectangleHeight},
                                                  this.selectedShape,
                                                  this.isFill,
                                                  this.lineWidth,
                                                  this.canvasColor,
                                                  this.sessionHeaderKey,
                                                  this.pageKey,
                                                  this.userUID);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
            }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'ellipse':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.draggingResizer == 0 || this.checkPointOnTheRectangle(this.startPoint.x, this.startPoint.y) == 1) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
              this.canvasService.saveCanvasData({
                x: this.startPointX,
                y: this.startPointY
                },
                {
                  x: this.positionX,
                  y: this.positionY
                },
                this.selectedShape,
                this.isFill,
                this.lineWidth,
                this.canvasColor,
                this.sessionHeaderKey,
                this.pageKey,
                this.userUID);
                }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'textarea':
          this.isDragging = false;
          this.createTextarea(event);
          break;
        case 'imagearea':
          this.isDragging = true;
          this.draggingResizer = this.anchorHitTest(this.startPoint.x, this.startPoint.y);
          if (this.draggingResizer == 0) {
            this.isDragging = false;
            this.draggingResizer = -1;
            this.isImageDragging = false;
            this.sendImageToServer();
          } else if (this.draggingResizer == 1) {
            this.draggingResizer = -1;
            this.isImageDragging = false;
            this.isDragging = false;
            this.restoreSnapshot();
            this.imageX = 50;
            this.imageY = 50;
            this.selectedShape = this.prevSelectedShape;
          } else {
            this.isImageDragging = this.draggingResizer < 0 && this.hitImage(this.startPoint.x, this.startPoint.y);
          }
          break;
        case 'eraser':
          this.takeSnapshot();
          this.points.push({ x: this.startPoint.x, y: this.startPoint.y });
          this.tempFillStyle = this.context.fillStyle;
          this.context.fillStyle = 'white';
          this.tempStrokeStyle = this.context.strokeStyle;
          this.context.strokeStyle = 'white';
          this.tempLineWidth = this.context.lineWidth;
          this.context.lineWidth = this.eraserWidth;
          this.canvasService.saveCanvasData(this.startPoint,
            this.startPoint,
            this.selectedShape,
            this.isFill,
            this.eraserWidth,
            'white',
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          break;
      }
    }
  }

  dragStartTouch(event) {
    event.preventDefault();
    if ( (event.target === this.canvasEl) && (event.touches.length == 1) && this.isCanvasEditor ) {
      this.isManualDrawing = true;
      this.isDragging = true;
      this.startPoint = this.getCanvasCoordinates({
        offsetX : event.changedTouches[0].clientX - this.canvasEl.getBoundingClientRect().left,
        offsetY : event.changedTouches[0].clientY - this.canvasEl.getBoundingClientRect().top
      }, true);
      switch (this.selectedShape) {
        case 'freehand':
          this.takeSnapshot();
          this.points.push({ x: this.startPoint.x, y: this.startPoint.y });
          this.canvasService.saveCanvasData(this.startPoint,
            this.startPoint,
            this.selectedShape,
            this.isFill,
            this.lineWidth,
            this.canvasColor,
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          break;
          case 'circle':
            if (this.isShapeSelected) {
              this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
              if (this.draggingResizer == 1) {
                this.restoreSnapshot();
              } else if (this.draggingResizer == 0 || this.checkPointOnTheCircle(this.startPoint.x, this.startPoint.y) == 1) {
                this.isShapeDragging = true;
              } else {
                this.restoreSnapshot();
                this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
                this.canvasService.saveCanvasData({
                                                    x: this.startPointX,
                                                    y: this.startPointY
                                                  },
                                                  {
                                                    x: this.positionX,
                                                    y: this.positionY
                                                  },
                                                  this.selectedShape,
                                                  this.isFill,
                                                  this.lineWidth,
                                                  this.canvasColor,
                                                  this.sessionHeaderKey,
                                                  this.pageKey,
                                                  this.userUID);
                this.pushSnapshot(false);
                this.sendPageDataToService();
                this.isShapeSelected = false;
                this.isShapeDrawn = true;
              }
            } else {
              this.takeSnapshot();
              this.startPointX = this.startPoint.x;
              this.startPointY = this.startPoint.y;
            }
          break;
        case 'line':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.checkPointOnTheLine(this.startPoint.x, this.startPoint.y) < 5 || this.draggingResizer == 0) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.canvasService.saveCanvasData({
                                                  x: this.startPointX,
                                                  y: this.startPointY
                                              },
                                              {
                                                x: this.positionX,
                                                y: this.positionY
                                              },
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
            }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'rectangle':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.draggingResizer == 0 || this.checkPointOnTheRectangle(this.startPoint.x, this.startPoint.y) == 1) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.canvasService.saveCanvasData({
                                                      x: this.startPointX,
                                                      y: this.startPointY
                                                  },
                                                  {x : this.rectangleWidth, y : this.rectangleHeight},
                                                  this.selectedShape,
                                                  this.isFill,
                                                  this.lineWidth,
                                                  this.canvasColor,
                                                  this.sessionHeaderKey,
                                                  this.pageKey,
                                                  this.userUID);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
            }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'ellipse':
          if (this.isShapeSelected) {
            this.draggingResizer = this.shapeAnchorHitTest(this.startPoint.x, this.startPoint.y);
            if (this.draggingResizer == 1) {
              this.restoreSnapshot();
            } else if (this.draggingResizer == 0 || this.checkPointOnTheRectangle(this.startPoint.x, this.startPoint.y) == 1) {
              this.isShapeDragging = true;
            } else {
              this.restoreSnapshot();
              this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
              this.pushSnapshot(false);
              this.sendPageDataToService();
              this.isShapeSelected = false;
              this.isShapeDrawn = true;
              this.canvasService.saveCanvasData({
                x: this.startPointX,
                y: this.startPointY
                },
                {
                  x: this.positionX,
                  y: this.positionY
                },
                this.selectedShape,
                this.isFill,
                this.lineWidth,
                this.canvasColor,
                this.sessionHeaderKey,
                this.pageKey,
                this.userUID);
                }
          } else {
            this.takeSnapshot();
            this.startPointX = this.startPoint.x;
            this.startPointY = this.startPoint.y;
          }
          break;
        case 'textarea':
          this.isDragging = false;
          this.createTextarea({
            offsetX : event.changedTouches[0].clientX - this.canvasEl.getBoundingClientRect().left,
            offsetY : event.changedTouches[0].clientY - this.canvasEl.getBoundingClientRect().top
          });
          break;
        case 'imagearea':
          this.isDragging = true;
          this.draggingResizer = this.anchorHitTest(this.startPoint.x, this.startPoint.y);
          if (this.draggingResizer == 0) {
            this.isDragging = false;
            this.draggingResizer = -1;
            this.isImageDragging = false;
            this.sendImageToServer();
          } else if (this.draggingResizer == 1) {
            this.draggingResizer = -1;
            this.isImageDragging = false;
            this.isDragging = false;
            this.restoreSnapshot();
            this.imageX = 50;
            this.imageY = 50;
            this.selectedShape = this.prevSelectedShape;
          } else {
            this.isImageDragging = this.draggingResizer < 0 && this.hitImage(this.startPoint.x, this.startPoint.y);
          }
          break;
        case 'eraser':
          this.takeSnapshot();
          this.points.push({ x: this.startPoint.x, y: this.startPoint.y });
          this.tempFillStyle = this.context.fillStyle;
          this.context.fillStyle = 'white';
          this.tempStrokeStyle = this.context.strokeStyle;
          this.context.strokeStyle = 'white';
          this.tempLineWidth = this.context.lineWidth;
          this.context.lineWidth = this.eraserWidth;
          this.canvasService.saveCanvasData(this.startPoint,
          this.startPoint,
          this.selectedShape,
          this.isFill,
          this.eraserWidth,
          'white',
          this.sessionHeaderKey,
          this.pageKey,
          this.userUID);
          break;
      }
   }
  }

  drag(event) {
    this.changeMouseCursor(false);
    if (this.isDragging && this.isCanvasEditor) {
      const position = this.getCanvasCoordinates(event, false);
      switch (this.selectedShape) {
        case 'freehand':
          this.canvasService.saveCanvasData(position,
                                            position,
                                            this.selectedShape,
                                            this.isFill,
                                            this.lineWidth,
                                            this.canvasColor,
                                            this.sessionHeaderKey,
                                            this.pageKey,
                                            this.userUID);
          this.drawFreehand(position);
          break;
        case 'circle':
          if (!this.isShapeDrawn) {
            if (this.isShapeSelected) {
              if (this.checkPointOnTheCircle(position.x, position.y) == 1) {
                this.changeMouseCursor(true);
              }
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawCircle(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            }
          }
          break;
        case 'line':
          if (!this.isShapeDrawn) {
            if (!this.isShapeSelected) {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawLine(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            } else {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            }
          }
          break;
        case 'rectangle':
          if (!this.isShapeDrawn) {
            if (this.isShapeSelected) {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawRectangle(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            }
          }
          break;
        case 'ellipse':
          if (!this.isShapeDrawn) {
            if (this.isShapeSelected) {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawEllipse(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            }
          }
          break;
        case 'eraser':
          this.drawFreehand(position);
          // this.eraseCanvas(position);
          // this.canvasService.saveCanvasData(position,
          //                                   {x:0,y:0},
          //                                   this.selectedShape,
          //                                   this.isFill,
          //                                   this.eraserWidth,
          //                                   this.canvasColor,
          //                                   this.sessionHeaderKey,
          //                                   this.pageKey,
          //                                   this.userUID);
          this.canvasService.saveCanvasData(position,
            position,
            this.selectedShape,
            this.isFill,
            this.eraserWidth,
            'white',
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          break;
        case 'imagearea':
          this.handleImageMouseMove(position.x, position.y);
          break;
      }
    } else if (this.isShapeSelected) {
      const position = this.getCanvasCoordinates(event, false);
      switch (this.selectedShape) {
        case 'circle':
          if (this.checkPointOnTheCircle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
        case 'line':
          if (this.checkPointOnTheLine(position.x, position.y) < 5) {
            this.changeMouseCursor(true);
          }
          break;
        case 'rectangle':
          if (this.checkPointOnTheRectangle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
        case 'ellipse':
          if (this.checkPointOnTheRectangle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
      }
    }
  }

  dragTouch(event) {
    this.finger = event.touches.length;
    event.preventDefault();
    if ((event.target === this.canvasEl) && (event.touches.length == 1) && this.isDragging && this.isCanvasEditor ) {
      const position = this.getCanvasCoordinates({
        offsetX : event.changedTouches[0].clientX - this.canvasEl.getBoundingClientRect().left,
        offsetY : event.changedTouches[0].clientY - this.canvasEl.getBoundingClientRect().top
      }, true);

      switch (this.selectedShape) {
        case 'freehand':
          this.canvasService.saveCanvasData(position,
                                            position,
                                            this.selectedShape,
                                            this.isFill,
                                            this.lineWidth,
                                            this.canvasColor,
                                            this.sessionHeaderKey,
                                            this.pageKey,
                                            this.userUID);
          this.drawFreehand(position);
          break;
        case 'circle':
          if (!this.isShapeDrawn) {
            if (this.isShapeSelected) {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawCircle(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            }
          }
          break;
        case 'line':
          if (!this.isShapeDrawn) {
            if (!this.isShapeSelected) {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawLine(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            } else {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            }
          }
          break;
        case 'rectangle':
        if (!this.isShapeDrawn) {
          if (this.isShapeSelected) {
            if (this.isShapeDragging) {
              this.handleShapeMouseMove(position.x, position.y);
            }
          } else {
            this.restoreSnapshot();
            this.context.setLineDash([6]);
            this.drawRectangle(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
          }
        }
        break;
        case 'ellipse':
          if (!this.isShapeDrawn) {
            if (this.isShapeSelected) {
              if (this.isShapeDragging) {
                this.handleShapeMouseMove(position.x, position.y);
              }
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([6]);
              this.drawEllipse(this.startPoint.x, this.startPoint.y, position.x, position.y, false);
            }
          }
          break;
        case 'eraser':
          this.drawFreehand(position);
          // this.eraseCanvas(position);
          // this.canvasService.saveCanvasData(position,
          //                                   {x:0,y:0},
          //                                   this.selectedShape,
          //                                   this.isFill,
          //                                   this.eraserWidth,
          //                                   this.canvasColor,
          //                                   this.sessionHeaderKey,
          //                                   this.pageKey,
          //                                   this.userUID);
          this.canvasService.saveCanvasData(position,
            position,
            this.selectedShape,
            this.isFill,
            this.eraserWidth,
            'white',
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          break;
        case 'imagearea':
          this.handleImageMouseMove(position.x, position.y);
          break;
      }
    }
  }

  dragStop(event) {
    this.changeMouseCursor(false);
    if (this.isDragging && this.isCanvasEditor) {
      this.isDragging = false;
      const position = this.getCanvasCoordinates(event, false);
      switch (this.selectedShape) {
        case 'freehand':
          this.canvasService.saveCanvasData(position,
                                            position,
                                            this.selectedShape,
                                            this.isFill,
                                            this.lineWidth,
                                            this.canvasColor,
                                            this.sessionHeaderKey,
                                            this.pageKey,
                                            this.userUID);
          this.drawFreehand(position);
          this.pushSnapshot(false);
          this.sendPageDataToService();
          break;
        case 'circle':
          if (this.isShapeDrawn) {
            this.isShapeDrawn = false;
          } else {
            if (this.isShapeSelected) {
              if (this.draggingResizer == 1) {
                this.isShapeSelected = false;
              }
              if (this.isShapeDragging) {
                this.isShapeDragging = false;
                this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
              }
              this.draggingResizer = -1;
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([]);
              this.drawCircle(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
              this.positionX = position.x;
              this.positionY = position.y;
              this.isShapeSelected = true;
            }
          }
          break;
        case 'line':
          if (this.isShapeDrawn) {
            this.isShapeDrawn = false;
          } else {
            if (!this.isShapeSelected) {
              this.restoreSnapshot();
              this.context.setLineDash([]);
              this.drawLine(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
              this.positionX = position.x;
              this.positionY = position.y;
              this.isShapeSelected = true;
            } else {
              if (this.draggingResizer == 1) {
                this.isShapeSelected = false;
              }
              if (this.isShapeDragging) {
                this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
                this.isShapeDragging = false;
              }
            }
          }
          break;
        case 'rectangle':
          if (this.isShapeDrawn) {
            this.isShapeDrawn = false;
          } else {
            if (this.isShapeSelected) {
              if (this.draggingResizer == 1) {
                this.isShapeSelected = false;
              }
              if (this.isShapeDragging) {
                this.isShapeDragging = false;
                this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
              }
              this.draggingResizer = -1;
            } else {
              this.restoreSnapshot();
              this.context.setLineDash([]);
              this.drawRectangle(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
              this.isShapeSelected = true;
              this.positionX = position.x;
              this.positionY = position.y;

              // this.pushSnapshot(false);
              // this.sendPageDataToService();
            }
          }
          break;
        case 'ellipse':
          if (this.isShapeDrawn) {
            this.isShapeDrawn = false;
          } else {
            if (this.isShapeSelected) {
              if (this.isShapeDragging) {
                this.isShapeDragging = false;
                this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
              }
              this.draggingResizer = -1;

            } else {
              this.restoreSnapshot();
              this.context.setLineDash([]);
              this.drawEllipse(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
              this.isShapeSelected = true;
              this.positionX = position.x;
              this.positionY = position.y;
            }
          }
          break;
        case 'eraser':
          // this.eraseCanvas(position);
          this.drawFreehand(position);
          this.canvasService.saveCanvasData(position,
            position,
            this.selectedShape,
            this.isFill,
            this.eraserWidth,
            'white',
            this.sessionHeaderKey,
            this.pageKey,
            this.userUID);
          this.context.fillStyle = this.tempFillStyle;
          this.context.strokeStyle = this.tempStrokeStyle;
          this.context.lineWidth = this.tempLineWidth;
          console.log('canvas color: ' + this.context.strokeStyle);
          // this.canvasService.saveCanvasData(position,
          //                                   {x:0,y:0},
          //                                    this.selectedShape,
          //                                    this.isFill,
          //                                    this.eraserWidth,
          //                                    this.canvasColor,
          //                                    this.sessionHeaderKey,
          //                                    this.pageKey,
          //                                    this.userUID);
          this.pushSnapshot(false);
          this.sendPageDataToService();
          break;
        case 'imagearea':
          this.handleImageMouseUp(event);
          break;
      }
    } else if (this.isShapeSelected) {
      const position = this.getCanvasCoordinates(event, false);
      switch (this.selectedShape) {
        case 'circle':
          if (this.checkPointOnTheCircle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
        case 'line':
          if (this.checkPointOnTheLine(position.x, position.y) < 5) {
            this.changeMouseCursor(true);
          }
          break;
        case 'rectangle':
          if (this.checkPointOnTheRectangle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
        case 'ellipse':
          if (this.checkPointOnTheRectangle(position.x, position.y) == 1) {
            this.changeMouseCursor(true);
          }
          break;
      }
    }
    this.points = [];
  }

  dragStopTouch(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.finger == 1 && this.isCanvasEditor) {
      if (this.isDragging) {
        this.isDragging = false;
        const position = this.getCanvasCoordinates({
          offsetX : event.changedTouches[0].clientX - this.canvasEl.getBoundingClientRect().left,
        offsetY : event.changedTouches[0].clientY - this.canvasEl.getBoundingClientRect().top
        }, true);
        switch (this.selectedShape) {
          case 'freehand':
            this.canvasService.saveCanvasData(position,
                                              position,
                                              this.selectedShape,
                                              this.isFill,
                                              this.lineWidth,
                                              this.canvasColor,
                                              this.sessionHeaderKey,
                                              this.pageKey,
                                              this.userUID);
            this.drawFreehand(position);
            this.pushSnapshot(false);
            this.sendPageDataToService();
            break;
            case 'circle':
              if (this.isShapeDrawn) {
                this.isShapeDrawn = false;
              } else {
                if (this.isShapeSelected) {
                  if (this.draggingResizer == 1) {
                    this.isShapeSelected = false;
                  }
                  if (this.isShapeDragging) {
                    this.isShapeDragging = false;
                    this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
                  }
                  this.draggingResizer = -1;
                } else {
                  this.restoreSnapshot();
                  this.context.setLineDash([]);
                  this.drawCircle(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
                  this.positionX = position.x;
                  this.positionY = position.y;
                  this.isShapeSelected = true;
                }
              }
              break;
          case 'line':
            if (this.isShapeDrawn) {
              this.isShapeDrawn = false;
            } else {
              if (!this.isShapeSelected) {
                this.restoreSnapshot();
                this.context.setLineDash([]);
                this.drawLine(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
                this.positionX = position.x;
                this.positionY = position.y;
                this.isShapeSelected = true;
              } else {
                if (this.draggingResizer == 1) {
                  this.isShapeSelected = false;
                }
                if (this.isShapeDragging) {
                  this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
                  this.isShapeDragging = false;
                }
              }
            }
            break;
          case 'rectangle':
            if (this.isShapeDrawn) {
              this.isShapeDrawn = false;
            } else {
              if (this.isShapeSelected) {
                if (this.draggingResizer == 1) {
                  this.isShapeSelected = false;
                }
                if (this.isShapeDragging) {
                  this.isShapeDragging = false;
                  this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
                }
                this.draggingResizer = -1;
              } else {
                this.restoreSnapshot();
                this.context.setLineDash([]);
                this.drawRectangle(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
                this.isShapeSelected = true;
                this.positionX = position.x;
                this.positionY = position.y;

                // this.pushSnapshot(false);
                // this.sendPageDataToService();
              }
            }
            break;
          case 'ellipse':
            if (this.isShapeDrawn) {
              this.isShapeDrawn = false;
            } else {
              if (this.isShapeSelected) {
                if (this.isShapeDragging) {
                  this.isShapeDragging = false;
                  this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
                }
                this.draggingResizer = -1;

              } else {
                this.restoreSnapshot();
                this.context.setLineDash([]);
                this.drawEllipse(this.startPoint.x, this.startPoint.y, position.x, position.y, true);
                this.isShapeSelected = true;
                this.positionX = position.x;
                this.positionY = position.y;
              }
            }
            break;
          case 'eraser':
            // this.eraseCanvas(position);
            this.drawFreehand(position);
            this.canvasService.saveCanvasData(position,
              position,
              this.selectedShape,
              this.isFill,
              this.eraserWidth,
              'white',
              this.sessionHeaderKey,
              this.pageKey,
              this.userUID);
            this.context.fillStyle = this.tempFillStyle;
            this.context.strokeStyle = this.tempStrokeStyle;
            this.context.lineWidth = this.tempLineWidth;
            console.log('canvas color: ' + this.context.strokeStyle);
            // this.canvasService.saveCanvasData(position,
            //                                   {x:0,y:0},
            //                                    this.selectedShape,
            //                                    this.isFill,
            //                                    this.eraserWidth,
            //                                    this.canvasColor,
            //                                    this.sessionHeaderKey,
            //                                    this.pageKey,
            //                                    this.userUID);
            this.pushSnapshot(false);
            this.sendPageDataToService();
            break;
          case 'imagearea':
            this.handleImageMouseUp(event);
            break;
        }
        this.points = [];
      }
    }
  }

  goToLogin() {
    this.zone.run(() => this.router.navigateByUrl('/login'));
  }

  // setUsername(name){
  //   this.username = name;
  // }

  signOut() {
    this.service.signOut(this);
  }

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.resizeCanvas();

    // clearTimeout(this.resizeTimeout);
    // this.resizeTimeout = setTimeout(this.res,200);

  }

  @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event?) {
      this.resizeCanvas();

    // clearTimeout(this.resizeTimeout);
    // this.resizeTimeout = setTimeout(this.res,200);

  }

  setCanvasBackground(selected) {
    this.selectedShape = 'background';
    switch (selected) {
      case 'hLine':
        this.canvasEl.style.backgroundSize = '25px 25px';
        this.canvasEl.style.backgroundImage = 'linear-gradient(to bottom, rgb(216, 233, 223) 1px, transparent 1px)';
        break;
      case 'vLine':
        this.canvasEl.style.backgroundSize = '25px 25px';
        this.canvasEl.style.backgroundImage = 'linear-gradient(to right, rgb(216, 233, 223) 1px, transparent 1px)';
        break;
      case 'dot':
        this.canvasEl.style.backgroundSize = '25px 25px';
        this.canvasEl.style.backgroundImage = 'radial-gradient(circle,#979797 1px, rgba(0, 0, 0, 0) 1px)';
        break;
      case 'grid':
        this.canvasEl.style.backgroundSize = '25px 25px';
        this.canvasEl.style.backgroundImage = 'linear-gradient(to right, rgba(229, 235, 237, 1) 1px, transparent 1px), linear-gradient(to bottom, rgba(229, 235, 237, 1) 1px, transparent 1px)';
        break;
    }
  }

  setUserUID(userUID) {
    this.userUID = userUID;
  }

  setSessionHeaderKey(sessionHeaderKey) {
    this.sessionHeaderKey = sessionHeaderKey;
    this.canvasService.removeCompleteRTC(this, this.sessionHeaderKey);
    window.localStorage.setItem('sessionHeaderKey', this.sessionHeaderKey);
  }

  setPageKey(pageKey) {
    this.pageKey = pageKey;
    this.isLoading = false;
  }

  pushPageKeys(pageKey) {
    // console.log("pagekey pushed");
    this.pageKeysArray.push(pageKey);
  }

  generateShareCode() {
    this.shareCode = Math.random().toString(36).substr(2, 6);
  }

  sendPageDataToService() {
    const url: string = this.canvasEl.toDataURL('image/png', 0.1);
    this.canvasService.sendPageData(this.sessionHeaderKey, this.pageKey, url, this.isWhiteBoard);
  }

  setPageCanvasImage(url) {
    if (url !== '') {
      this.canvasImage.nativeElement.src = url;
    }
  }

  monitorPageData(sessionHeaderKey) {
    this.items$ = this.canvasService.monitorPageData(sessionHeaderKey, this.isWhiteBoard);
  }

  createPage() {
    this.isLoading = true;
    this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    const tempFillStyle = this.context.fillStyle;
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    this.context.fillStyle = tempFillStyle;
    if (this.selectedShape === 'imagearea') {
      this.selectedShape = this.prevSelectedShape;
    }
    this.canvasService.createNewPage(this, this.sessionHeaderKey);
    this.canvasService.resetCanvasCounts();
  }

  setCurrentPage(item: any) {
    // var img = new Image();
    // img.onload = function(){
    //   this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
    //   this.context.drawImage(img, 0, 0, this.canvasEl.width, this.canvasEl.height);
    //   if(currentPageKey !== this.pageKey ){
    //     this.pageKey = currentPageKey;
    //   }
    // }.bind(this)
    // img.src = dataURL;
    // img.crossOrigin = "Anonymous";
      if (item.payload.key !== this.pageKey ) {
        if (this.selectedShape === 'imagearea') {
          this.selectedShape = this.prevSelectedShape;
        }
        this.pageKey = item.payload.key;
        this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        const tempFillStyle = this.context.fillStyle;
        this.context.fillStyle = 'white';
        this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
        this.context.fillStyle = tempFillStyle;
        this.snapshotArrayPage = [];
        this.points = [];
        this.stepPage = -1;
        this.stepPage++;
        if (this.isWhiteBoard) {
          this.canvasService.resetCanvasCounts();
          this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
        } else {
          this.snapshotArrayPage.push(item.payload.val().originUrl);
          this.powerPointUrl = item.payload.val().originUrl;
        }
        this.isManualDrawing = false;
        if (this.isCanvasAdmin) {
          this.canvasService.setCurrentPage(this.sessionHeaderKey, this.pageKey);
        }
        if (this.isWhiteBoard) {
          this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
        } else {
          const img = new Image();
          img.onload = function() {
            this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
            this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
          }.bind(this);
          img.src = item.payload.val().originUrl;
          img.crossOrigin = 'Anonymous';
        }
      }

  }

  setResumeCanvasSession(pageKey) {
    // Set Current Page
    this.setPageKey(pageKey);
    this.canvasService.removeCollaboratorPermission(this, this.sessionHeaderKey, this.userUID);
  }

  setResumePowerpointSession(pageKey, pageData) {
    this.pageKey = pageKey;
    const img = new Image();
    img.onload = function() {
      this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
      this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
      this.canvasService.removeCollaboratorPermission(this, this.sessionHeaderKey, this.userUID);
    }.bind(this);
    img.src = pageData;
    img.crossOrigin = 'Anonymous';
  }

  drawRemoteLine(startPointX, startPointY, currentPointX, currentPointY) {
    this.context.beginPath();
    this.context.moveTo(startPointX, startPointY);
    this.context.lineTo(currentPointX, currentPointY);
    this.context.stroke();
  }

  drawRemoteCircle(startPointX, startPointY, currentPointX, currentPointY, isFill) {
    const radius = Math.sqrt(Math.pow((startPointX - currentPointX), 2) + Math.pow((startPointY - currentPointY), 2));
    this.context.beginPath();
    this.context.arc(startPointX, startPointY, radius, 0, 2 * Math.PI, false);
    if (isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
  }

  drawRemoteEllipse(startPointX, startPointY, currentPointX, currentPointY, isFill) {
    this.context.save();
    this.context.beginPath();
    //Dynamic scaling
    const scalex = 1 * ((currentPointX - startPointX) / 2);
    const scaley = 1 * ((currentPointY - startPointY) / 2);
    this.context.scale(scalex, scaley);
    //Create ellipse
    const centerx = (startPointX / scalex) + 1;
    const centery = (startPointY / scaley) + 1;
    this.context.arc(centerx, centery, 1, 0, 2 * Math.PI);
    //Restore and draw
    this.context.restore();
    if (isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
  }

  drawRemoteFreehand(startPointX, startPointY, currentPointX, currentPointY) {

    this.points.push({ x: startPointX, y: startPointY });
    if (this.points.length > 1) {
      this.restoreSnapshot();
      let p1 = this.points[0];
      let p2 = this.points[1];
      this.context.beginPath();
      this.context.moveTo(p1.x, p1.y);

      for (let i = 1, len = this.points.length; i < len; i++) {
        // we pick the point between pi+1 & pi+2 as the
        // end point and p1 as our control point
        const midPoint = this.midPointBtw(p1, p2);
        this.context.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
        p1 = this.points[i];
        p2 = this.points[i + 1];
      }
      // Draw last line as a straight line while
      // we wait for the next point to be able to calculate
      // the bezier control point
      this.context.lineTo(p1.x, p1.y);
      this.context.stroke();
    } else {
      this.takeSnapshot();
    }


    // this.context.beginPath();
    // this.context.moveTo(startPointX, startPointY);
    // this.context.lineTo(currentPointX, currentPointY);
    // this.context.stroke();
  }

  drawRemoteRectangle(startPointX, startPointY, rectangleWidth, rectangleHeight, isFill) {
    this.context.beginPath();
    this.context.rect(startPointX, startPointY, rectangleWidth, rectangleHeight);
    if (isFill) {
      this.context.fill();
    } else {
      this.context.stroke();
    }
  }

  setRemoteCanvasText(startPointX, startPointY, inputText, remote) {
    this.context.font = '20px sans-serif';
    this.context.fillText(inputText, startPointX, startPointY);
    if (!remote) {
      this.sendPageDataToService();
    }
  }

  setRemoteCanvasImage(x, y, width, height, url) {
    console.log('drawing image canvas');
    this.isRemoteImageLoading = true;
    this.isLoading = true;
    const img = new Image();
    img.onload = function() {
      // x = x * this.canvasEl.width / this.canvasEl.clientWidth | 0;
      // y = y * this.canvasEl.height / this.canvasEl.clientHeight | 0;
      this.context.drawImage(img, x, y, width, height);
      this.pushSnapshot();
      this.isRemoteImageLoading = false;
      if (this.remoteTempCoordinatesArray.length == 0) {
        this.isLoading = false;
      } else {
        for (let i = 0; i < this.remoteTempCoordinatesArray.length; i++) {
          this.isLoading = true;
          if (this.remoteTempCoordinatesArray[i].type === 'undo' || this.remoteTempCoordinatesArray[i].type === 'redo' || this.remoteTempCoordinatesArray[i].type === 'imagearea') {
            this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
              this.remoteTempCoordinatesArray[i].startPointY,
              this.remoteTempCoordinatesArray[i].currentPointX,
              this.remoteTempCoordinatesArray[i].currentPointY,
              this.remoteTempCoordinatesArray[i].type,
              this.remoteTempCoordinatesArray[i].isFill,
              this.remoteTempCoordinatesArray[i].lineWidth,
              this.remoteTempCoordinatesArray[i].canvasColor,
              this.remoteTempCoordinatesArray[i].userUID,
              this.remoteTempCoordinatesArray[i].pageKey);
              if (i + 1 == this.remoteTempCoordinatesArray.length) {
                this.isLoading = false;
              }
              this.remoteTempCoordinatesArray.splice(0, i + 1);
              break;
          } else {
            this.remoteDraw(this.remoteTempCoordinatesArray[i].startPointX,
              this.remoteTempCoordinatesArray[i].startPointY,
              this.remoteTempCoordinatesArray[i].currentPointX,
              this.remoteTempCoordinatesArray[i].currentPointY,
              this.remoteTempCoordinatesArray[i].type,
              this.remoteTempCoordinatesArray[i].isFill,
              this.remoteTempCoordinatesArray[i].lineWidth,
              this.remoteTempCoordinatesArray[i].canvasColor,
              this.remoteTempCoordinatesArray[i].userUID,
              this.remoteTempCoordinatesArray[i].pageKey);
          }

          if (this.remoteTempCoordinatesArray.length == i + 1) {
            this.remoteTempCoordinatesArray = [];
            this.isLoading = false;
          }
        }
      }
    }.bind(this);
    img.src = url;
    img.crossOrigin = 'Anonymous';
  }


  eraseRemoteCanvas(positionX, positionY, eraserWidth) {
    this.context.fillStyle = 'white';
    this.context.beginPath();
    this.context.arc(positionX, positionY, eraserWidth, 0, 2 * Math.PI, false);
    this.context.fill();
  }

  remoteDraw(startPointX, startPointY, currentPointX, currentPointY, type, isFill, lineWidth, canvasColor, userUID, pageKey) {
    if (pageKey === this.pageKey) {
      if (this.isManualDrawing) {
        if (this.userUID !== userUID) {
          if (this.isRemoteImageLoading) {
            this.remoteTempCoordinatesArray.push({
              startPointX: startPointX,
              startPointY: startPointY,
              currentPointX: currentPointX,
              currentPointY: currentPointY,
              type: type,
              isFill: isFill,
              lineWidth: lineWidth,
              canvasColor: canvasColor,
              userUID: userUID,
              pageKey: pageKey
            });
          } else {
            this.context.strokeStyle = canvasColor;
            this.context.fillStyle = canvasColor;
            this.context.lineWidth = lineWidth;
            switch (type) {
              case 'freehand':
                this.drawRemoteFreehand(startPointX, startPointY, currentPointX, currentPointY);
                break;
              case 'circle':
                this.drawRemoteCircle(startPointX, startPointY, currentPointX, currentPointY, isFill);
                break;
              case 'line':
                this.drawRemoteLine(startPointX, startPointY, currentPointX, currentPointY);
                break;
              case 'rectangle':
                this.drawRemoteRectangle(startPointX, startPointY, currentPointX, currentPointY, isFill);
                break;
              case 'ellipse':
                this.drawRemoteEllipse(startPointX, startPointY, currentPointX, currentPointY, isFill);
                break;
              case 'eraser':
                this.drawRemoteFreehand(startPointX, startPointY, currentPointX, currentPointY);
                break;
              case 'pushSnapshot':
                this.pushSnapshot(true);
                this.points = [];
                break;
              case 'undo':
                this.undoCanvas(true);
                break;
              case 'redo':
                this.redoCanvas(true);
                break;
              case 'textarea':
                this.setRemoteCanvasText(startPointX, startPointY, currentPointX, true);
                break;
              case 'imagearea':
                this.setRemoteCanvasImage(startPointX, startPointY, currentPointX, currentPointY, isFill); //isFill is holding the url for this condition
                break;
              case 'background':
                this.setCanvasBackground(startPointX); //the selected background is stored in the startPointX
                break;
            }
            this.context.strokeStyle = this.canvasColor;
            this.context.fillStyle = this.canvasColor;
            this.context.lineWidth = this.lineWidth;
          }
        }
      } else {
        if (this.isRemoteImageLoading) {
          this.remoteTempCoordinatesArray.push({
            startPointX: startPointX,
            startPointY: startPointY,
            currentPointX: currentPointX,
            currentPointY: currentPointY,
            type: type,
            isFill: isFill,
            lineWidth: lineWidth,
            canvasColor: canvasColor,
            userUID: userUID,
            pageKey: pageKey
          });
        } else {
          this.context.strokeStyle = canvasColor;
          this.context.fillStyle = canvasColor;
          this.context.lineWidth = lineWidth;
          switch (type) {
            case 'freehand':
              this.drawRemoteFreehand(startPointX, startPointY, currentPointX, currentPointY);
              break;
            case 'circle':
              this.drawRemoteCircle(startPointX, startPointY, currentPointX, currentPointY, isFill);
              break;
            case 'line':
              this.drawRemoteLine(startPointX, startPointY, currentPointX, currentPointY);
              break;
            case 'rectangle':
              this.drawRemoteRectangle(startPointX, startPointY, currentPointX, currentPointY, isFill);
              break;
            case 'ellipse':
              this.drawRemoteEllipse(startPointX, startPointY, currentPointX, currentPointY, isFill);
              break;
            case 'eraser':
              this.drawRemoteFreehand(startPointX, startPointY, currentPointX, currentPointY);
              break;
            case 'pushSnapshot':
              this.points = [];
              this.pushSnapshot(true);
              break;
            case 'undo':
              this.undoCanvas(true);
              break;
            case 'redo':
              this.redoCanvas(true);
              break;
            case 'textarea':
              this.setRemoteCanvasText(startPointX, startPointY, currentPointX, true);
              break;
            case 'imagearea':
              this.setRemoteCanvasImage(startPointX, startPointY, currentPointX, currentPointY, isFill); //isFill is holding the url for this condition
              break;
            case 'background':
              this.setCanvasBackground(startPointX); //the selected background is stored in the startPointX
              break;
          }
          this.context.strokeStyle = this.canvasColor;
          this.context.fillStyle = this.canvasColor;
          this.context.lineWidth = this.lineWidth;
        }
      }
    }
  }

  monitorCanvasCoordinates(sessionHeaderKey, pageKey) {
    this.canvasService.monitorCanvasCoordinates(this, sessionHeaderKey, pageKey);
  }

  goToDashboard() {
    this.zone.run(() => this.router.navigateByUrl(''));
  }

  setAccessType(isAdmin: boolean, isViewer: boolean, requestSent) {
    this.isCanvasAdmin = isAdmin;
    this.isCanvasViewer = isViewer;
    this.isRequestSent = requestSent;
    console.log('Request sent: ' + requestSent);
    this.isSessionRequestSending = false;
    if (this.isCanvasAdmin) {
      this.startRecordBtn.nativeElement.style.display = '';
      this.isRecordingStop = true;
      // this.canvasService.removeCompleteRTC(this, this.sessionHeaderKey);
      this.userAccessType = 'Admin';
      this.isCanvasEditor = true;
      this.canvasService.removePermission(this.sessionHeaderKey, this.userUID);
      this.canvasService.setCurrentPage(this.sessionHeaderKey, this.pageKey);
      this.canvasService.getRTCusers(this, this.sessionHeaderKey);
      this.micDisableBtnDiv.nativeElement.style.display = '';
      this.micEnableBtnDiv.nativeElement.style.display = 'none';

    } else if (this.isCanvasViewer) {
      this.userAccessType = 'Viewer';
      this.isCanvasEditor = false;
      if (this.firstLoad) {
        // this.canvasService.removeSingleUser(this, this.userUID, this.sessionHeaderKey);
        this.canvasService.monitorPageRemoval(this, this.sessionHeaderKey);
        this.canvasService.monitorSessionActivity(this, this.sessionHeaderKey, this.isWhiteBoard);
        this.firstLoad = false;
      }
      this.voiceRequestBtnDiv.nativeElement.style.display = '';
    } else {
      this.userAccessType = 'Collaborator';
      this.isCanvasEditor = true;
      if (this.firstLoad) {
        // this.canvasService.removeSingleUser(this, this.userUID, this.sessionHeaderKey);
        this.canvasService.monitorPageRemoval(this, this.sessionHeaderKey);
        this.canvasService.monitorSessionActivity(this, this.sessionHeaderKey, this.isWhiteBoard);
        this.firstLoad = false;
      }
      this.voiceRequestBtnDiv.nativeElement.style.display = '';
    }
    this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
  }

  saveMessage() {
    if (this.textMsg !== '') {
      this.canvasService.saveCanvasMessages(this, this.sessionHeaderKey, this.userUID, this.textMsg, 'message');
    }
    this.textMsg = '';
  }

  pushCanvasMessageArray(message, type, username, userUID, imageUrl, messagekey) {
    if (imageUrl === '') {
      imageUrl = '../../assets/images/user_avatar.png';
    }
    if (type === 'message') {
      let isSelf = false;
      if (userUID === this.userUID) {
        isSelf = true;
      }
      this.messagesArray.push({
        message: message,
        type: type,
        username: username,
        userImage: imageUrl,
        isSelf: isSelf
      });
      this.messagesArrayObservable = new Observable(observer => {
        observer.next(this.messagesArray);
      } );
    } else {
      if (this.isCanvasAdmin) {
        let messageBody = '';
        if (type === 'request') {
          console.log('Request sent by ' + username);
          messageBody = ' wants to collaborate';
        } else {
          messageBody = ' wants to voice chat';
        }
        messageBody = username + messageBody;
        this.requestsArray.push({
          message: messageBody,
          userUID: userUID,
          messagekey: messagekey,
          userImage: imageUrl,
          type: type
        });
        this.requestsArrayObservable = new Observable(observer => {
          observer.next(this.requestsArray);
        } );
      }
    }
  }

  getCanvasMessages() {
    this.canvasService.getCanvasMessages(this, this.sessionHeaderKey);
  }


  sendAccessRequest() {
    this.canvasService.saveCanvasMessages(this, this.sessionHeaderKey, this.userUID, this.textMsg, 'request');
    this.isSessionRequestSending = true;
  }

  onRequestSendSuccess() {
    console.log('request sending successfull');
    this.isSessionRequestSending = false;
    this.isRequestSent = true;
  }

  onRequestAccept(userUID, messagekey, requestAccepted: boolean, type) {
    if (type === 'request') {
      this.canvasService.onRequestAccept(userUID, messagekey, this.sessionHeaderKey, requestAccepted);
    } else {
      this.canvasService.onVoiceRequestAccept(userUID, messagekey, this.sessionHeaderKey, requestAccepted);
    }
  }

  removeRequesItem(messagekey) {
    for (let i = 0; i < this.requestsArray.length; i++) {
      if (this.requestsArray[i].messagekey === messagekey) {
        this.requestsArray.splice(i, 1);
        break;
      }
    }
    this.requestsArrayObservable = new Observable(observer => {
      observer.next(this.requestsArray);
    });
  }

  // initiateWebRTCAdmin(userUID){
  //   for(var i = 0; i < this.RTCpeersArray.length; i++){
  //     if(this.RTCpeersArray[i].userUID === userUID){
  //       this.RTCpeersArray[i].RTCobject.close();
  //       this.RTCpeersArray.splice(i,1);
  //       break;
  //     }
  //   }
  //   var pc = new RTCPeerConnection(SERVERS, DEFAULT_CONSTRAINTS);
  //   var remoteAudioEl = document.createElement("audio");
  //   pc.onicecandidate = function(event){
  //     this.canvasService.sendRtcDetails("new-ice-candidate", userUID, "admin", JSON.stringify(event.candidate), this.sessionHeaderKey);
  //   }.bind(this);
  //   pc.ontrack = function(event){
  //     // remoteAudioEl.src = (window.URL ? URL : webkitURL).createObjectURL(event.streams[0]);
  //     remoteAudioEl.srcObject = event.streams[0];
  //     remoteAudioEl.play();
  //     this.isChatting = true;
  //   }.bind(this);
  //   pc.onnegotiationneeded = function(event){
  //     pc.createOffer().then(function(offer) {
  //       return pc.setLocalDescription(offer);
  //     }.bind(this))
  //     .then(function() {
  //       this.canvasService.sendRtcDetails("video-offer", userUID, "admin", JSON.stringify(pc.localDescription), this.sessionHeaderKey);
  //     }.bind(this))
  //     .catch(error => {
  //       console.log(error);
  //     });
  //   }.bind(this);
  //   // this.pc.onremovetrack = handleRemoveTrackEvent;
  //   // this.pc.oniceconnectionstatechange = handleICEConnectionStateChangeEvent;
  //   // this.pc.onicegatheringstatechange = handleICEGatheringStateChangeEvent;
  //   // this.pc.onsignalingstatechange = handleSignalingStateChangeEvent;
  //   navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then(stream => {
  //       // audio_elem.src = URL.createObjectURL(stream);
  //       // audio_elem.play();
  //       this.adminStreeam = stream;
  //       stream.getTracks().forEach(track => pc.addTrack(track, stream));
  //     }).catch(error => {
  //       console.log(error);
  //     })
  //   this.RTCpeersArray.push({
  //     userUID: userUID,
  //     RTCobject: pc,
  //     audio: remoteAudioEl
  //   });
  // }

  startVoiceChat(userUID, voiceRequest) {
    let isUserExist = false;
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      if (this.RTCpeersArray[i].userUID === userUID) {
        isUserExist = true;
        break;
      }
    }

    console.log('isUSEReXIST startVoicechat ' + isUserExist);

    if (userUID !== this.userUID ) {
      if (!isUserExist) {
        const pc = new RTCPeerConnection(SERVERS, DEFAULT_CONSTRAINTS);
        const remoteAudioEl = document.createElement('audio');
        pc.onicecandidate = function(event) {
          this.canvasService.sendRtcDetails('new-ice-candidate', userUID, this.userUID, JSON.stringify(event.candidate), this.sessionHeaderKey);
        }.bind(this);
        pc.ontrack = function(event) {
          // remoteAudioEl.src = (window.URL ? URL : webkitURL).createObjectURL(event.streams[0]);
          remoteAudioEl.srcObject = event.streams[0];
          remoteAudioEl.play();
          this.isChatting = true;
        }.bind(this);
        pc.onnegotiationneeded = function(event) {
          pc.createOffer().then(function(offer) {
            return pc.setLocalDescription(offer);
          }.bind(this))
          .then(function() {
            this.canvasService.sendRtcDetails('video-offer', userUID, this.userUID, JSON.stringify(pc.localDescription), this.sessionHeaderKey);
          }.bind(this))
          .catch(error => {
            console.log(error);
          });
        }.bind(this);
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.streamArray.push(stream);
            stream.getTracks().forEach(track => pc.addTrack(track, stream));
            console.log('stream length ' + stream.getAudioTracks().length);
            if (!this.isCanvasAdmin && !this.isUserMicEnabled) {
              stream.getAudioTracks()[0].enabled = false;
            }
          }).catch(error => {
            console.log(error);
          });
        this.RTCpeersArray.push({
          userUID: userUID,
          RTCobject: pc,
          audio: remoteAudioEl
        });
      }
    } else {
      if (voiceRequest) {
        this.isVoiceRequestSent = true;
      } else {
        this.isVoiceRequestSent = false;
      }
    }
  }

  receiveVoiceCall(userUID) {

    let isUserExist = false;
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      if (this.RTCpeersArray[i].userUID === userUID) {
        isUserExist = true;
        break;
      }
    }

    console.log('receive voice call userEXisat ' + isUserExist);

    if (userUID !== this.userUID ) {
      if (!isUserExist) {
        const pc = new RTCPeerConnection(SERVERS, DEFAULT_CONSTRAINTS);
      const remoteAudioEl = document.createElement('audio');
      pc.onicecandidate = function(event) {
        this.canvasService.sendRtcDetails('new-ice-candidate', userUID, this.userUID, JSON.stringify(event.candidate), this.sessionHeaderKey);
      }.bind(this);
      pc.ontrack = function(event) {
        // this.remoteAudioEl.src = (window.URL ? URL : webkitURL).createObjectURL(event.streams[0]);
        remoteAudioEl.srcObject = event.streams[0];
        remoteAudioEl.play();
        this.isChatting = true;
      }.bind(this);
      pc.onnegotiationneeded = function(event) {
        pc.createOffer().then(function(offer) {
        }.bind(this))
        .catch(error => {
          console.log(error);
        });
      }.bind(this);
      this.RTCpeersArray.push({
        userUID: userUID,
        RTCobject: pc,
        audio: remoteAudioEl
      });
      console.log('hello1');
      }

    }
  }

  // initiateWebRTCpairing(){
  //   this.remoteAudioEl = document.createElement("audio");
  //   this.pcObj = new RTCPeerConnection(SERVERS, DEFAULT_CONSTRAINTS);
  //   this.pcObj.onicecandidate = function(event){
  //     this.canvasService.sendRtcDetails("new-ice-candidate", "admin", this.userUID, JSON.stringify(event.candidate), this.sessionHeaderKey);
  //   }.bind(this);
  //   this.pcObj.ontrack = function(event){
  //     // this.remoteAudioEl.src = (window.URL ? URL : webkitURL).createObjectURL(event.streams[0]);
  //     this.remoteAudioEl.srcObject = event.streams[0];
  //     this.remoteAudioEl.play();
  //     this.isChatting = true;
  //   }.bind(this);
  //   this.pcObj.onnegotiationneeded = function(event){
  //     this.pcObj.createOffer().then(function(offer) {
  //     }.bind(this))
  //     .catch(error => {
  //       console.log(error);
  //     });
  //   }.bind(this);
  //   this.canvasService.startRtc(this,this.userUID, this.sessionHeaderKey);
  //   this.canvasService.monitorRtcDetails(this, this.sessionHeaderKey);
  // }

  createAnswerWebRTC(type, target, content, userUID) {
    if (target === this.userUID) {
      if (type === 'video-answer') {
        const desc = new RTCSessionDescription(JSON.parse(content));
        for (let i = 0; i < this.RTCpeersArray.length; i++) {
          if (this.RTCpeersArray[i].userUID === userUID) {
            // console.log("loop 1");
            this.RTCpeersArray[i].RTCobject.setRemoteDescription(desc);
            break;
          }
        }
        // this.pc.setRemoteDescription(desc);
      } else if (type === 'video-offer') {
        const desc = new RTCSessionDescription(JSON.parse(content));
        for (let i = 0; i < this.RTCpeersArray.length; i++) {
          if (this.RTCpeersArray[i].userUID === userUID) {
            const index = i;
            this.RTCpeersArray[index].RTCobject.setRemoteDescription(desc).then(function () {
              return navigator.mediaDevices.getUserMedia({ audio: true });
            }.bind(this))
            .then(function(stream) {

              this.streamArray.push(stream);
              console.log('stream length ' + stream.getAudioTracks().length);
              stream.getTracks().forEach(track =>

                this.RTCpeersArray[index].RTCobject.addTrack(track, stream)
              );
              if (!this.isCanvasAdmin && !this.isUserMicEnabled) {
                stream.getAudioTracks()[0].enabled = false;
              }
            }.bind(this))
            .then(function() {
              return this.RTCpeersArray[index].RTCobject.createAnswer();
            }.bind(this))
            .then(function(answer) {
              return this.RTCpeersArray[index].RTCobject.setLocalDescription(answer);
            }.bind(this))
            .then(function() {
              this.canvasService.sendRtcDetails('video-answer', userUID, this.userUID, JSON.stringify(this.RTCpeersArray[index].RTCobject.localDescription), this.sessionHeaderKey);
            }.bind(this));
            // .catch(error => {
            //   console.log("Error" + error);
            // });
            break;
          }
        }
      } else {
        const candidate = new RTCIceCandidate(JSON.parse(content));
        for (let i = 0; i < this.RTCpeersArray.length; i++) {
          if (this.RTCpeersArray[i].userUID === userUID) {
            // console.log("loop 1");
            this.RTCpeersArray[i].RTCobject.addIceCandidate(candidate)
            .catch(Error => {
              console.log(Error => console.log(Error));
            });
            break;
          }
        }
      }
    }
  }


  monitorRTCusers() {
    this.canvasService.monitorRTCusers(this, this.sessionHeaderKey);
  }

  muteAudio() {
    console.log('mutting audio');
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      this.RTCpeersArray[i].audio.muted = true;
    }
    this.muteBtnDiv.nativeElement.style.display = 'none';
    this.unmuteBtnDiv.nativeElement.style.display = '';
  }

  unmuteAudio() {
    console.log('unmutting audio');
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      this.RTCpeersArray[i].audio.muted = false;
    }
    this.unmuteBtnDiv.nativeElement.style.display = 'none';
    this.muteBtnDiv.nativeElement.style.display = '';
  }

  removeRTCuser(userUID) {
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      if (this.RTCpeersArray[i].userUID === userUID) {
        this.RTCpeersArray[i].RTCobject.close();
        this.RTCpeersArray.splice(i, 1);
        break;
      }
    }
  }

  resetRTCparameters() {
    if (this.pcObj) {
      this.pcObj.close();
      this.pcObj = null;
      this.isMute = false;
      this.isChatting = false;
      // this.initiateWebRTCpairing();
    } else {
      // this.initiateWebRTCpairing();
    }
  }

  deletePage(pageKey) {
    if (this.pageKeysArray.length > 1) {
      if (window.confirm('Are sure you want to delete this page ?')) {
        this.isLoading = true;
        this.deletePageKey = pageKey;
        this.canvasService.deletePage(this, this.sessionHeaderKey, pageKey);
      }
    }
  }

  onPageDeleteSuccess() {
    // console.log("deleted page key " + this.deletePageKey);
    // console.log("current page key" + this.pageKey);
    this.isLoading = false;
    for (let i = 0; i < this.pageKeysArray.length; i++) {
      if (this.pageKeysArray[i] === this.deletePageKey) {
        if (this.deletePageKey === this.pageKey) {
          this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
          if ((i + 1) == this.pageKeysArray.length) {
            this.pageKey = this.pageKeysArray[i - 1];
          } else {
            this.pageKey = this.pageKeysArray[i + 1];
          }
          const tempFillStyle = this.context.fillStyle;
          this.context.fillStyle = 'white';
          this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
          this.context.fillStyle = tempFillStyle;
          this.snapshotArrayPage = [];
          this.stepPage = 0;
          if (this.isWhiteBoard) {
            this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
          }
          this.isManualDrawing = false;
          this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
        }
        this.pageKeysArray.splice(i, 1);
        break;
      }
    }

  }

  onPageDeleteSuccessNonAdmin(deletePageKey) {
    // console.log("deleted page key " + deletePageKey);
    // console.log("current page key" + this.pageKey);
    for (let i = 0; i < this.pageKeysArray.length; i++) {
      if (this.pageKeysArray[i] === deletePageKey) {
        if (deletePageKey === this.pageKey) {
          this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
          if ((i + 1) == this.pageKeysArray.length) {
            this.pageKey = this.pageKeysArray[i - 1];
          } else {
            this.pageKey = this.pageKeysArray[i + 1];
          }
          this.snapshotArrayPage = [];
          this.stepPage = 0;
          if (this.isWhiteBoard) {
            this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
          }
          this.isManualDrawing = false;
          this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
        }
        this.pageKeysArray.splice(i, 1);
        break;
      }
    }

  }

  onKey(event) {
    event.target.size = event.target.value.length + 1 ;
  }

  startRecordingSession() {
    if (this.isCanvasAdmin) {
      navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        // audio_elem.src = URL.createObjectURL(stream);
        // audio_elem.play();
        const finalStream = new MediaStream();
        this.canvasCapture = this.recordCanvas.captureStream();
        stream.getTracks().forEach(track => finalStream.addTrack(track));
        this.canvasCapture.getTracks().forEach(track => finalStream.addTrack(track));
        this.recorder = RecordRTC(finalStream, {
          type: 'video'
      });

      this.recorder.startRecording();
      this.startRecordBtn.nativeElement.style.display = 'none';
      this.stopRecordBtn.nativeElement.style.display = '';
      }).catch(error => {
        console.log(error);
      });
    } else {
      this.showSnackbar('You dont have permission to record');
    }
  }
  
  stopRecordingSession() {
    this.stopRecordBtn.nativeElement.style.display = 'none';
    this.uploadProgressBtn.nativeElement.style.display = '';
    this.recorder.stopRecording(blob => {
      const file = new File([this.recorder.getBlob()], 'testVideo', {
        type: 'video/webm'
      });
      this.modalTitle.nativeElement.innerHTML = 'Uploading Video';
      this.canvasService.saveSessionVideo(this, file);
    });
  }

  enableStreaming() {
    if (this.isCanvasAdmin) {
      // this.adminStreeam.getAudioTracks()[0].enabled = true;
    } else {
      // this.othersStream.getAudioTracks()[0].enabled = true;
    }
    this.isStreaming = true;
  }

  disableStreaming() {
    if (this.isCanvasAdmin) {
      // this.adminStreeam.getAudioTracks()[0].enabled = false;
    } else {
      // this.othersStream.getAudioTracks()[0].enabled = false;
    }
    this.isStreaming = false;
  }

  testingStopAudio() {
    for (let i = 0; i < this.RTCpeersArray.length; i++) {
      this.RTCpeersArray[i].audio.muted = true;
    }
  }

  setVideoUploadPercentage(percent) {
    this.videoProgressBar.nativeElement.style.width = percent + '%';
    this.videoProgressBar.nativeElement.innerHTML = percent;
    console.log('percent' + percent);
  }

  onVideoUploadSuccess(url) {
    this.videoUrl = url;
    this.thumbnailUpload();
    // this.canvasService.saveSessionVideoUrl(this, this.sessionHeaderKey, videoUrl, this.userUID);
  }

  thumbnailUpload() {
    this.thumbnailCanvasUrl = this.canvasEl.toDataURL('image/png', 0.1);
    fetch(this.thumbnailCanvasUrl)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'tesing.jpg');
        this.modalTitle.nativeElement.innerHTML = 'Generating thumbnail';
        this.canvasService.saveThumbnail(this, file);
      });

  }

  onThumbnailUploadSuccess(thumbnailUrl) {
    this.canvasService.saveSessionVideoUrl(this, this.sessionHeaderKey, this.videoUrl, this.userUID, thumbnailUrl);
  }

  onSavingVideoSessionSuccess() {
    this.uploadProgressBtn.nativeElement.style.display = 'none';
    this.startRecordBtn.nativeElement.style.display = '';
    this.thumbnailCanvasUrl = '';
  }

  checkCurrentPage(currentPageKey, originUrl) {
    if (currentPageKey !== this.pageKey) {
      this.pageKey = currentPageKey;
      this.context.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      const tempFillStyle = this.context.fillStyle;
      this.context.fillStyle = 'white';
      this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      this.context.fillStyle = tempFillStyle;
      this.snapshotArrayPage = [];
      this.points = [];
      this.stepPage = -1;
      this.stepPage++;
      if (this.isWhiteBoard) {
        this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
      } else {
        this.snapshotArrayPage.push(originUrl);
      }
      if (this.isWhiteBoard) {
        this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
      } else {
        const img = new Image();
        img.onload = function() {
          this.context.drawImage(img, 0, 0, this.canvasWidth, this.canvasHeight);
          this.canvasService.monitorCanvasCoordinates(this, this.sessionHeaderKey, this.pageKey);
        }.bind(this);
        img.src = originUrl;
        img.crossOrigin = 'Anonymous';
      }
    }
  }

  // Draw image
  drawImage(withAnchors, withBorders) {
    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.restoreSnapshot();
    this.context.drawImage(this.selectedImage, 0, 0, this.selectedImage.width, this.selectedImage.height, this.imageX, this.imageY, this.imageWidth, this.imageHeight);
    if (withAnchors) {
      this.drawTickAnchor(this.imageX, this.imageY);
      this.drawCrossAnchor(this.imageRight, this.imageY);
      this.drawDragAnchor(this.imageRight, this.imageBottom);
      this.drawDragAnchor(this.imageX, this.imageBottom);
    }
  }

  drawDragAnchor(x, y) {
    const tempFillStyle = this.context.fillStyle;
    this.context.beginPath();
    this.context.arc(x, y, this.resizerRadius, 0, this.pi2, false);
    this.context.closePath();
    this.context.fillStyle = '#080808';
    this.context.fill();
    this.context.fillStyle = tempFillStyle;
  }

  drawCrossAnchor(x, y) {
    const tempLineWidth = this.context.lineWidth;
    const tempStrokeStyle = this.context.strokeStyle;
    this.context.strokeStyle = '#080808';
    this.context.lineWidth = 3;
    this.context.beginPath();
    this.context.moveTo(x - 4, y - 4);
    this.context.lineTo(x + 4, y + 4);
    this.context.stroke();

    this.context.moveTo(x + 4, y - 4);
    this.context.lineTo(x - 4, y + 4);
    this.context.stroke();
    this.context.beginPath();
    this.context.arc(x, y, this.resizerRadius, 0, this.pi2, false);
    this.context.closePath();
    this.context.stroke();
    this.context.lineWidth = tempLineWidth;
    this.context.strokeStyle = tempStrokeStyle;
  }

  drawTickAnchor(x, y) {
    const tempLineWidth = this.context.lineWidth;
    const tempStrokeStyle = this.context.strokeStyle;
    this.context.strokeStyle = '#080808';
    this.context.lineWidth = 3;
    this.context.beginPath();
    this.context.moveTo(x - 5, y - 3);
    this.context.lineTo(x - 2, y + 5);
    this.context.lineTo(x + 7, y - 2);
    this.context.stroke();
    this.context.beginPath();
    this.context.arc(x, y, this.resizerRadius, 0, this.pi2, false);
    this.context.closePath();
    this.context.stroke();
    this.context.lineWidth = tempLineWidth;
    this.context.strokeStyle = tempStrokeStyle;
  }

  anchorHitTest(x, y) {
    let dx, dy;

    // top-left
    dx = x - this.imageX;
    dy = y - this.imageY;
    if (dx * dx + dy * dy <= this.rr) {
        return (0);
    }
    // top-right
    dx = x - this.imageRight;
    dy = y - this.imageY;
    if (dx * dx + dy * dy <= this.rr) {
        return (1);
    }
    // bottom-right
    dx = x - this.imageRight;
    dy = y - this.imageBottom;
    if (dx * dx + dy * dy <= this.rr) {
        return (2);
    }
    // bottom-left
    dx = x - this.imageX;
    dy = y - this.imageBottom;
    if (dx * dx + dy * dy <= this.rr) {
        return (3);
    }
    return (-1);
  }

  hitImage(x , y) {
    return (x > this.imageX && x < this.imageX + this.imageWidth && y > this.imageY && y < this.imageY + this.imageHeight);
  }

  initialiseSelectedImage(src) {
    this.rr = this.resizerRadius * this.resizerRadius;
    this.selectedImage = new Image();
    this.selectedImage.onload = function() {
      this.imageWidth = 200;
      this.imageHeight = 200;
      this.imageRight = this.imageX + this.imageWidth;
      this.imageBottom = this.imageY + this.imageHeight;
      this.drawImage(true, false);
    }.bind(this);
    this.selectedImage.src = src;
    this.selectedImage.crossOrigin = 'Anonymous';

  }

  handleImageMouseMove(positionX, positionY) {
    if (this.draggingResizer > -1) {
          // resize the image
        switch (this.draggingResizer) {
          // case 0:
          //     //top-left
          //     this.imageX = positionX;
          //     this.imageWidth = this.imageRight - positionX;
          //     this.imageY = positionY;
          //     this.imageHeight = this.imageBottom - positionY;
          //     break;
          // case 1:
          //     //top-right
          //     this.imageY = positionY;
          //     this.imageWidth = positionX - this.imageX;
          //     this.imageHeight = this.imageBottom - positionY;
          //     break;
          case 2:
              //bottom-right
              this.imageWidth = positionX - this.imageX;
              this.imageHeight = positionY - this.imageY;
              if (this.imageWidth < 25) {this.imageWidth = 25; }
              if (this.imageHeight < 25) {this.imageHeight = 25; }

              // set the image right and bottom
              this.imageRight = this.imageX + this.imageWidth;
              this.imageBottom = this.imageY + this.imageHeight;

              // redraw the image with resizing anchors
              this.drawImage(true, true);
              break;
          case 3:
              //bottom-left
              this.imageX = positionX;
              this.imageWidth = this.imageRight - positionX;
              this.imageHeight = positionY - this.imageY;
              if (this.imageWidth < 25) {this.imageWidth = 25; }
              if (this.imageHeight < 25) {this.imageHeight = 25; }

              // set the image right and bottom
              this.imageRight = this.imageX + this.imageWidth;
              this.imageBottom = this.imageY + this.imageHeight;

              // redraw the image with resizing anchors
              this.drawImage(true, true);
              break;
          }
        } else if (this.isImageDragging) {
          const dx = positionX - this.startPoint.x;
          const dy = positionY - this.startPoint.y;
          this.imageX += dx;
          this.imageY += dy;
          this.imageRight += dx;
          this.imageBottom += dy;
          this.startPoint.x = positionX;
          this.startPoint.y = positionY;
          this.drawImage(false, true);
        }
  }

  handleShapeMouseMove(posX, posY) {
    if (this.draggingResizer == 0) {
      this.restoreSnapshot();
      const dx = posX - this.positionX;
      const dy = posY - this.positionY;
      this.positionX += dx;
      this.positionY += dy;
      switch (this.selectedShape) {
        case 'line':
          this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
          break;
        case 'rectangle':
          this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
          break;
        case 'circle':
          this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
          break;
        case 'ellipse':
          this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, true);
          break;

      }
      this.positionX = posX;
      this.positionY = posY;
    } else {
      this.restoreSnapshot();
      const dx = posX - this.startPoint.x;
      const dy = posY - this.startPoint.y;
      this.startPointX += dx;
      this.startPointY += dy;
      this.positionX += dx;
      this.positionY += dy;
      switch (this.selectedShape) {
        case 'line':
          this.drawLine(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          break;
        case 'rectangle':
          this.drawRectangle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          break;
        case 'circle':
          this.drawCircle(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          break;
        case 'ellipse':
          this.drawEllipse(this.startPointX, this.startPointY, this.positionX, this.positionY, false);
          break;

      }
      this.startPoint.x = posX;
      this.startPoint.y = posY;
    }
  }

  handleImageMouseUp(event) {
    this.draggingResizer = -1;
    this.isImageDragging = false;
    this.drawImage(true, false);
  }

  checkPointOnTheLine(pointX, pointY) {
    const Dx = this.positionX - this.startPointX;
    const Dy = this.positionY - this.startPointY;
    const d = Math.abs(Dy * pointX - Dx * pointY - this.startPointX * this.positionY + this.positionX * this.startPointY) / Math.sqrt(Math.pow(Dx, 2) + Math.pow(Dy, 2));
    return d;
  }

  checkPointOnTheRectangle(pointX, pointY) {
    if ( this.startPointX <= pointX && pointX <= this.positionX && this.startPointY <= pointY && pointY <= this.positionY ) {
      return 1;
    } else {
      return -1;
    }
  }

  checkPointOnTheCircle(pointX, pointY) {
    let dx, dy;
    dx = pointX - this.startPointX;
    dy = pointY - this.startPointY;
    if (dx * dx + dy * dy <= this.circleRadius * this.circleRadius) {
      return 1;
    } else {
      return -1;
    }
  }

  shapeAnchorHitTest(x, y) {
    let dx, dy;

    // top-left
    dx = x - this.positionX;
    dy = y - this.positionY;
    if (dx * dx + dy * dy <= this.rr) {
        return (0);
    }
    dx = x - this.startPointX;
    dy = y - this.startPointY;
    if (dx * dx + dy * dy <= this.rr) {
      return (1);
    }
    return(-1);
  }

  changeMouseCursor(isShapeMove: boolean) {
    if (isShapeMove) {
      this.canvasEl.style.cursor = 'move';
    } else {
      this.canvasEl.style.cursor = 'crosshair';
    }
  }

  showRTCusers(userUID, userName, userImage, isMicEnabled, key) {
    if (this.userUID !== userUID) {
      this.chatUsersArray.push({
        userName: userName,
        isMicEnabled: isMicEnabled,
        userImage: userImage,
        key: key
      });
      this.chatUsersArrayObservable = new Observable(observer => {
        observer.next(this.chatUsersArray);
      } );
    }
  }

  onRTCusersChanged(type, key, isMicEnabled) {

    for (let i = 0; i < this.chatUsersArray.length; i++) {
      if (this.chatUsersArray[i].key === key) {
        if (type === 'child_changed') {
          this.chatUsersArray[i].isMicEnabled = isMicEnabled;
        } else if (type === 'child_removed') {
          this.chatUsersArray.splice(i, 1);
        }
        break;
      }
    }
    this.chatUsersArrayObservable = new Observable(observer => {
      observer.next(this.chatUsersArray);
    } );
  }

  changeMicStatus(userUID, isMicEnabled, voiceRequest) {
    console.log('changing mic ');
    if (userUID === this.userUID && !this.isCanvasAdmin) {
      if (voiceRequest) {
        this.isVoiceRequestSent = true;
      } else {
        this.isVoiceRequestSent = false;
      }
      this.isVoiceRequestSending = false;
      if (isMicEnabled) {
        for (let i = 0; i < this.streamArray.length; i++) {
          this.streamArray[i].getAudioTracks()[0].enabled = true;
        }
        this.micDisableBtnDiv.nativeElement.style.display = '';
        this.micEnableBtnDiv.nativeElement.style.display = 'none';
        this.voiceRequestBtnDiv.nativeElement.style.display = 'none';
        this.isUserMicEnabled  = isMicEnabled;
        this.chatRequestModalCloseBtn.nativeElement.click();
      } else {
        for (let i = 0; i < this.streamArray.length; i++) {
          this.streamArray[i].getAudioTracks()[0].enabled = false;
        }
        this.micDisableBtnDiv.nativeElement.style.display = 'none';
        this.micEnableBtnDiv.nativeElement.style.display = 'none';
        this.voiceRequestBtnDiv.nativeElement.style.display = '';
        if (this.isUserMicEnabled) {
          this.isVoiceRequestSent = false;
        }
        this.isUserMicEnabled = isMicEnabled;
      }
    }
  }

  changeUserMicStatus(key, isMicEnabled) {
    console.log('changing user mic status');
    this.canvasService.changeUserMic(key, this.sessionHeaderKey, !isMicEnabled);
  }

  disableMic() {
    for (let i = 0; i < this.streamArray.length; i++) {
      this.streamArray[i].getAudioTracks()[0].enabled = false;
    }
    this.micDisableBtnDiv.nativeElement.style.display = 'none';
    this.micEnableBtnDiv.nativeElement.style.display = '';
  }

  enableMic() {
    for (let i = 0; i < this.streamArray.length; i++) {
      this.streamArray[i].getAudioTracks()[0].enabled = true;
    }
    this.micDisableBtnDiv.nativeElement.style.display = '';
    this.micEnableBtnDiv.nativeElement.style.display = 'none';
  }

  sendVoiceRequest() {
    this.canvasService.saveCanvasMessages(this, this.sessionHeaderKey, this.userUID, this.textMsg, 'voice');
    this.isVoiceRequestSending = true;
  }

  showLeftColorPicker() {
    if (!this.isColorDialogOpen) {
      this.leftToolbarInputColor.nativeElement.click();
      this.isColorDialogOpen = true;
    } else {
      this.isColorDialogOpen = false;
    }
  }

  showRightColorPicker() {
    if (!this.isColorDialogOpen) {
      this.rightToolbarInputColor.nativeElement.click();
      this.isColorDialogOpen = true;
    } else {
      this.isColorDialogOpen = false;
    }
  }


  showSnackbar(message) {
    const x = document.getElementById('snackbar');
    x.className = 'show';
    x.innerHTML = message;
    setTimeout(function() { x.className = x.className.replace('show', ''); }, 3000);
  }

  swapToolbars() {
    if (this.rightToolbar2.nativeElement.className === 'leftToolbar2') {
      this.rightToolbar2.nativeElement.className = 'rightToolbar2';
    } else {
      this.rightToolbar2.nativeElement.className = 'leftToolbar2';
    }
    if (this.rightToolbar1.nativeElement.style.display === 'none') {
      this.rightToolbar1.nativeElement.style.display = '';
    } else {
      this.rightToolbar1.nativeElement.style.display = 'none';
    }
    if (this.leftToolbar1.nativeElement.style.display === 'none') {
      this.leftToolbar1.nativeElement.style.display = '';
    } else {
      this.leftToolbar1.nativeElement.style.display = 'none';
    }
  }

  toggleShapePopover() {
    if (this.isShapePopover) {
      this.modalTitle.nativeElement.click();
    }
    this.isShapePopover = !this.isShapePopover;
  }

  toggleLineWidthPopover() {
    if (this.isLineWidthPopover) {
      this.modalTitle.nativeElement.click();
    }
    this.isLineWidthPopover = !this.isLineWidthPopover;
  }

  clearCanvasByPage() {
    if (this.isCanvasAdmin) {
      if (window.confirm('Are sure you want to clear this page ?')) {
        this.isLoading = true;
        this.canvasService.clearCanvasByPage(this, this.sessionHeaderKey, this.pageKey);
      }
    } else {
      this.showSnackbar('You dont have permission to clear session');
    }
  }

  onClearCanvasByPageSuccess(pageKey) {
    if (this.pageKey === pageKey) {
      const tempFillStyle = this.context.fillStyle;
      this.context.fillStyle = 'white';
      this.context.fillRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      this.context.fillStyle = tempFillStyle;
      this.snapshotArrayPage = [];
      this.points = [];
      this.stepPage = -1;
      this.stepPage++;
      if (this.isWhiteBoard) {
        this.snapshotArrayPage.push(this.canvasEl.toDataURL('image/png', 0.1));
      } else {
        this.snapshotArrayPage.push(this.powerPointUrl);
      }
      this.isLoading = false;
      if (this.isCanvasAdmin) {
        this.sendPageDataToService();
        this.showSnackbar('Canvas is cleared successfully');
      }
    }

  }

  stopLoading() {
    this.isLoading = false;
  }

}
